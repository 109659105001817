import { css, createGlobalStyle } from "styled-components";
import { theme } from "./theme";

import avenirBlack from "./fonts/avenirBlack.woff";
import avenirMedium from "./fonts/avenirMedium.woff";
import avenirRoman from "./fonts/avenirRoman.woff";
import avenirOblique from "./fonts/avenirOblique.woff";

const commonFontFaceStyles = css`
    font-display: block;
`;

export const GlobalStyles = createGlobalStyle`
    @font-face {
        ${commonFontFaceStyles};
        font-family: avenir;
        font-style: ${theme.fontStyle.normal};
        font-weight: ${theme.fontWeight.heavy};
        src: url(${avenirBlack}) format('truetype');
    }

    @font-face {
        ${commonFontFaceStyles};
        font-family: avenir;
        font-style: ${theme.fontStyle.normal};
        font-weight: ${theme.fontWeight.medium};
        src: url(${avenirMedium}) format('truetype');
    }

    @font-face {
        ${commonFontFaceStyles};
        font-family: avenir;
        font-style: ${theme.fontStyle.normal};
        font-weight: ${theme.fontWeight.normal};
        src: url(${avenirRoman}) format('truetype');
    }

    @font-face {
        ${commonFontFaceStyles};
        font-family: avenir;
        font-style: ${theme.fontStyle.italic};
        font-weight: ${theme.fontWeight.normal};
        src: url(${avenirOblique}) format('truetype');
    }

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: inherit;
        font-size: inherit;
        font-style: inherit;
        font-weight: inherit;
        font-family: inherit;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html,
    body,
    #___gatsby,
    #___gatsby > #gatsby-focus-wrapper {
        width: 100vw;
        min-height: 100vh;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    html.smoothScroll {
        scroll-behavior: smooth;
    }

    body {
        font-size: ${theme.typography.body.fontSize};
        font-family: avenir, sans-serif;
        font-weight: ${theme.fontWeight.normal};
        background-color:  ${theme.color.background.default};
        color: ${theme.color.background.contrastText};
        overflow-x: hidden;
    }

    em {
        font-family: ${theme.fontFamily.italic};
    }

    ul {
        list-style: none;
    }

    /* remove pixels below display: inline */
    img {
        display: block;
    }

    button {
        background: transparent;
        border: none;
        cursor: pointer;

        :hover {
            outline: none;
        }
    }

    a {
        text-decoration: none;
        cursor: pointer;
    }

    address,
    em {
        font-style: normal;
    }

    [disabled] {
        pointer-events: none;
    }

    :focus {
        outline: 2px solid ${theme.color.focus};
    }

    /* prevents focus after click, see layouts/index */
    body:not(.user-is-tabbing) a:focus,
    body:not(.user-is-tabbing) button:focus,
    body:not(.user-is-tabbing) input:focus,
    body:not(.user-is-tabbing) select:focus,
    body:not(.user-is-tabbing) textarea:focus {
        outline: none;
    }

    /* disable document scrolling while search dimmer is open*/
    .searchDimmerPreventScroll {
        overflow: hidden;
    }

    #hs-eu-cookie-confirmation,
    #hs-modal-content {
        font-size: ${theme.typography.disclaimer.fontSize} !important;
        font-family: avenir, sans-serif !important;
        font-weight: ${theme.typography.disclaimer.fontWeight} !important;
    }

    #hs-eu-policy-wording {
        margin-left: 16px !important;
        margin-right: 20px !important;
        margin-top: 0px !important;
        margin-bottom: 20px !important;
        font-size: 1.1em !important;
    }
    #hs-eu-policy-wording a {
        text-decoration: underline !important;
    }
    #hs-eu-cookie-confirmation-inner strong {
        font-weight: 800;
    }
    #hs-eu-cookie-confirmation-buttons-area button,
    #hs-modal-footer-container button {
        text-transform: uppercase;
        font-weight: ${theme.fontWeight.heavy};

    }

    #hs-banner-parent *:focus {
        outline: none;
    }

    #hs-eu-cookie-confirmation #hs-eu-confirmation-button {
        border: 2px solid var(--hs-banner-accept-border-color,var(--hs-banner-accentColor,#330066)) !important;
    }
    #hs-eu-cookie-confirmation #hs-eu-decline-button {
        border: 2px solid var(--hs-banner-decline-border-color,var(--hs-banner-accentColor,#330066)) !important;
    }
    #hs-eu-cookie-confirmation #hs-eu-cookie-settings-button {
        border: 2px solid var(--hs-banner-settings-text-color,var(--hs-banner-accentColor,#425b76)) !important;
    }

    #hs-eu-cookie-settings-button {
        text-decoration: none !important;
    }

    #hs-eu-decline-button  {
        order: -1;
    }
    .hs-close-button svg {
        transform: scale(1.5);
    }

    #hs-eu-cookie-disclaimer {
        margin-left: 16px;
        margin-right: 20px;
        margin-top: -.25em;
    }
`;
