import { Link as GatsbyLink, Link } from "gatsby";
import { Icon } from "../Icon";
import React, { ComponentProps } from "react";
import styled, { css } from "styled-components";
import { IconType } from "../../types";
import { regular12Type } from "../../styles/typography";
import { isProductMarketplaceUrl, isDemoSandboxUrl } from "../../util/link";
import { addSegmentQueryParams, useSegment } from "../../util/segment";

type OutboundLinkProps = {
    newTab?: boolean;
} & ComponentProps<"a">;

/**
 * A replacement for the vanilla `<a>` component intended for use with links
 * to any external URL's. Adds some useful behavior like opening links in
 * a new tab by default and automatically adding tracking query params.
 *
 * @param {boolean} newTab - Determines if link will open a new tab on click. Defaults to `true`.
 * @param {...*} ...props - Any other props that a regular `<a>` can take.
 *
 * @example
 * Use it in place of a regular `<a>`
 * ```tsx
 * <OutboundLink href="https://www.example.com/">Let's go</OutboundLink>
 * ```
 *
 * @example
 * You can style it like a regular `<a>`
 * ```tsx
 * const CoolLink = styled(OutboundLink)`
 *     color: red;
 * `;
 *
 * <CoolLink href="https://www.example.com/">I'm outta here</CoolLink>
 * ```
 */
export const OutboundLink = ({
    newTab = true,
    ...props
}: OutboundLinkProps) => {
    let anchorProps = { ...props };

    if (newTab) {
        if (
            isProductMarketplaceUrl(anchorProps.href) ||
            isDemoSandboxUrl(anchorProps.href)
        ) {
            // SEO: allow the marketplace to see easyagile.com as the document.referrer
            anchorProps = { rel: "noopener", target: "_blank", ...anchorProps };
        } else {
            // all other websites strip this info out.
            anchorProps = {
                rel: "noopener noreferrer",
                target: "_blank",
                ...anchorProps,
            };
        }
    }

    const segment = useSegment();
    anchorProps.href = addSegmentQueryParams(props.href, segment);
    // if there's no link don't try to render a link.
    const hasLink = Boolean(props.href);
    if (!hasLink) {
        return <span>{props.children}</span>;
    }

    return <FlexibleLink hasLink={Boolean(props.href)} {...anchorProps} />;
};

const FlexibleLink = styled.a<{ hasLink: boolean }>`
    cursor: ${(p) => (p.hasLink ? "pointer" : "default")};
`;

export const internalInlineLinkStyle = css`
    text-decoration: underline;
`;

const Underline = styled.span`
    border-bottom: 2px solid currentColor;
`;

interface AnchorProps {
    isInline?: boolean;
    isFloatRight?: boolean;
    isCTA?: boolean;
    color?: string;
}

const anchorStyles = css<AnchorProps>`
    ${regular12Type}
    float: ${(p) => (p.isFloatRight ? "right" : "unset")};

    color: ${(p) => p.color || "unset"};

    :hover {
        color: ${(p) => p.theme.color.secondaryTwo.main};

        svg > * {
            fill: ${(p) => p.theme.color.secondaryTwo.main};
        }
    }

    ${(p) =>
        p.isCTA &&
        css`
            color: ${p.theme.color.temp.utilityWhite};

            svg {
                fill: ${p.theme.color.temp.utilityWhite};
            }
        `}
`;

interface IconProps {
    isInternal?: boolean;
    isCTA: boolean;
}

const RenderIcon: React.FC<IconProps> = ({
    isInternal = false,
    isCTA = false,
}) => {
    const getSize = () => {
        if (isCTA) {
            return "m";
        }

        return "s";
    };

    return (
        <Icon
            type={isInternal ? IconType.rightArrow : IconType.externalLink}
            size={getSize()}
            isMarginLeft
        />
    );
};

const RenderText = ({ children }) => {
    return <Underline>{children}</Underline>;
};

const InternalLinkAnchor = styled.a<AnchorProps>`
    ${anchorStyles};
`;

type InternalLinkProps = AnchorProps & {
    to: string;
};

export const InternalLink: React.FC<InternalLinkProps> = ({
    isInline = false,
    isFloatRight = false,
    isCTA = false,
    children,
    ...rest
}) => (
    <InternalLinkAnchor
        as={GatsbyLink}
        isFloatRight={isFloatRight}
        isCTA={isCTA}
        {...rest}
    >
        <RenderText>{children}</RenderText>
        {!isInline && <RenderIcon isCTA={isCTA} isInternal />}
    </InternalLinkAnchor>
);

const ExternalLinkAnchor = styled(OutboundLink)<AnchorProps>`
    ${anchorStyles};
`;

type ExternalLinkProps = AnchorProps & {
    href: string;
};

export const ExternalLink: React.FC<ExternalLinkProps> = ({
    isInline = false,
    isFloatRight = false,
    isCTA = false,
    children,
    ...rest
}) => (
    <ExternalLinkAnchor {...rest} isCTA={isCTA} isFloatRight={isFloatRight}>
        <RenderText>{children}</RenderText>

        {!isInline && <RenderIcon isCTA={isCTA} />}
    </ExternalLinkAnchor>
);
