import React from "react";
import { AlgoliaInstantSearch } from "./AlgoliaInstantSearch";
import { SearchResultsDropDown } from "./SearchResultsDropDown";
import { useSearchUIMode } from "./SearchUIModeProvider";
import { SearchBarToggleButton } from "./SearchBarToggleButton";

export const SearchBar = () => {
    /** this is whether or not we are just showing a button or the Algolia <SearchBox> */
    const [isSearchActive] = useSearchUIMode();

    return (
        <AlgoliaInstantSearch isSearchActive={isSearchActive}>
            <SearchBarToggleButton />

            {isSearchActive && (
                <>
                    {/*  This is hits the <Hits/> dropdown */}
                    <SearchResultsDropDown />
                </>
            )}
        </AlgoliaInstantSearch>
    );
};
