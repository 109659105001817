import { checkBool } from "./src/constants";

export enum GatsbyDeployMode {
    Production = "Production",
    CMSPreview = "CMSPreview",
    PRPreview = "PRPreview",
    LocalDev = "LocalDev",
}

export const getDeployEnvironment = (
    forceCheck: boolean = false
): GatsbyDeployMode => {
    // Allow passing back the environment variable if it's set
    if (!forceCheck && process.env.GATSBY_DEPLOY_MODE) {
        return process.env.GATSBY_DEPLOY_MODE as GatsbyDeployMode;
    }

    // This is a draft sanity preview build
    if (checkBool(process.env.GATSBY_IS_PREVIEW)) {
        return GatsbyDeployMode.CMSPreview;
    }

    const IS_CLOUD_BUILD = checkBool(process.env.NETLIFY);

    if (!IS_CLOUD_BUILD) {
        return GatsbyDeployMode.LocalDev;
    }

    if (process.env.CONTEXT === "deploy-preview") {
        return GatsbyDeployMode.PRPreview;
    }

    // There are three other states: production, branch-deploy, and dev. We likely won't ever see dev, since that's
    // specific to Netlify Develop. We could see branch-deploy, though, and I'm not sure how to handle that.

    return GatsbyDeployMode.Production;
};
