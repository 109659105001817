import { useLocation } from "@reach/router";
import React from "react";
import styled from "styled-components";
import { isCloudBuild, PRODUCTION_DOMAINS } from "../constants";

const StickyBanner = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: ${(p) => p.theme.color.error.main};
    color: ${(p) => p.theme.color.error.contrastText};
    transform: translate(-100px, 75px) rotate(-30deg);
    z-index: 500;
    width: 500px;
    height: 50px;

    pointer-events: none;
`;

export const DevBanner = () => {
    const location = useLocation();

    // Don't show if the dataset is not development or we're not in the cloud
    if (process.env.GATSBY_SANITY_DATASET !== "development" || !isCloudBuild())
        return null;

    // Don't ever show this on a production domain
    if (PRODUCTION_DOMAINS.includes(location.hostname)) return null;

    return (
        <StickyBanner>
            <span>Development data in use</span>
        </StickyBanner>
    );
};
