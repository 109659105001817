import React, { useContext, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import Button from "../Button";
import { NavLinkType, navLists } from "./data";
import { ASK_QUESTION, BLOG_PATH, GET_DEMO } from "../../constants/urls";
import { Flex } from "../ThemeFlex";
import {
    NavItemWrapper,
    SubNavWrapper,
    CompanyLogo,
    NavBar,
    NavHeading,
    TopLayoutContent,
    NavHeadingText,
    TextLink,
} from "./navComponents";
import { Hyperlink } from "../Link/Hyperlink";
import { gridSquares } from "../../styles/grid";
import { regular12Type } from "../../styles/typography";
import { SearchBar } from "../Search/SearchBar";
import { useSearchUIMode } from "../Search/SearchUIModeProvider";
import { MdChevronRight } from "@react-icons/all-files/md/MdChevronRight";
import { Logo } from "../icons/Logo";
import { Arrow } from "../icons/Arrow";
import { useLocation } from "@reach/router";

const TopLayoutRight = styled.div`
    height: 100%;
    display: flex;
    right: 0;
`;

const ButtonWrapper = styled.div`
    margin-left: ${gridSquares(2)};
    vertical-align: middle;
    align-self: center;
`;

const RichMenuWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);
    background-color: ${(p) => p.theme.color.secondaryOne.main};
    gap: ${gridSquares(4)} ${gridSquares(1.5)};
    padding: ${gridSquares(2)};
    width: 732px;
`;

const ProductIconWrapper = styled.div<{ border?: string }>`
    border: 1px solid ${(p) => p.border};
    border-radius: 8px;
`;

const RichLink = styled(Hyperlink)<{ children?: React.ReactNode }>`
    justify-content: flex-end;

    ${regular12Type}
    font-weight: ${(p) => p.theme.fontWeight.medium};

    padding: 0px;
    font-weight: ${(p) => p.theme.fontWeight.bold};
    color: ${(p) => p.theme.color.primary.contrastText};
`;

const CopyText = styled.div`
    ${regular12Type}
    font-weight: ${(p) => p.theme.fontWeight.medium};

    padding: 0px;
    color: ${(p) => p.theme.color.primary.contrastText};
`;

const CTAButton = styled(Button)`
    padding: ${gridSquares(0.25)} ${gridSquares(0.75)};
`;

const RichContentHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: ${gridSquares(1.5)} ${gridSquares(1)};
`;

const RichContentCopyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: ${gridSquares(1.5)};
`;

const RichContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${gridSquares(2)};
`;

const CTAWrapper = styled.div`
    display: flex;
    justify-self: flex-end;
    margin-top: ${gridSquares(0.5)};
`;

interface RichNavLinkType {
    heading: string;
    copy?: string;
    icon?: string;
    url: string;
    ctaLink?: string;
    ctaText?: string;
    setIsOpen: (setOpen: boolean) => void;
    border?: string;
}

const RichMenuItem = ({
    heading,
    url,
    copy,
    icon,
    ctaLink,
    ctaText,
    setIsOpen,
    border,
}: RichNavLinkType) => {
    return (
        <RichContentWrapper>
            <RichLink href={url} onClick={() => setIsOpen(false)}>
                <RichContentCopyWrapper>
                    <RichContentHeader>
                        <ProductIconWrapper border={border}>
                            <img src={icon} alt="" width="48" height="48" />
                        </ProductIconWrapper>
                        <div>
                            <span>Easy Agile</span>
                            <br />
                            <Flex flexDirection="row" alignItems="center">
                                {heading}
                                <MdChevronRight />
                            </Flex>
                        </div>
                    </RichContentHeader>
                    <CopyText>{copy}</CopyText>
                </RichContentCopyWrapper>
            </RichLink>
            <CTAWrapper>
                <CTAButton
                    href={ctaLink}
                    onClick={() => setIsOpen(false)}
                    variation="lightOutline"
                >
                    {ctaText}
                </CTAButton>
            </CTAWrapper>
        </RichContentWrapper>
    );
};

interface NavItemType {
    heading: string;
    links?: NavLinkType[];
    link?: string;
}

const NavItem = ({ heading, link, links }: NavItemType) => {
    // consider: we have to wait for gatsby/ react hydration before nav works.  CSS hover effect instead?
    const [isOpen, setIsOpen] = useState(false);
    const [isSearchActive, setIsSearchActive] = useSearchUIMode();

    if (link)
        return (
            <NavItemWrapper>
                <Hyperlink href={link}>
                    <NavHeading>
                        <NavHeadingText>{heading}</NavHeadingText>
                    </NavHeading>
                </Hyperlink>
            </NavItemWrapper>
        );

    const isRichContent =
        links.filter((linkItem) => linkItem.ctaText && linkItem.ctaLink)
            .length === links.length;

    return (
        <NavItemWrapper
            onMouseEnter={() => {
                if (!isSearchActive) {
                    setIsOpen(true);
                }
            }}
            onMouseDown={() => {
                if (isSearchActive) {
                    // if we're in search mode get out of it
                    setIsSearchActive(false);
                    setIsOpen(true);
                }
            }}
            onMouseLeave={() => setIsOpen(false)}
        >
            <NavHeading>
                <NavHeadingText>{heading}</NavHeadingText>
                <Arrow
                    direction={isOpen ? "up" : "down"}
                    color="white"
                    size="8px"
                />
            </NavHeading>
            <SubNavWrapper $isOpen={isOpen}>
                {isRichContent ? (
                    <RichMenuWrapper>
                        {links.map((subMenuLink) => (
                            <RichMenuItem
                                key={subMenuLink.heading}
                                heading={subMenuLink.heading}
                                url={subMenuLink.url}
                                copy={subMenuLink.copy}
                                icon={subMenuLink.icon}
                                ctaLink={subMenuLink.ctaLink}
                                ctaText={subMenuLink.ctaText}
                                setIsOpen={setIsOpen}
                                border={subMenuLink.border}
                            />
                        ))}
                    </RichMenuWrapper>
                ) : (
                    links.map((textLink) => (
                        <TextLink
                            key={textLink.url}
                            url={textLink.url}
                            onClick={() => setIsOpen(false)}
                        >
                            <span>{textLink.heading}</span>
                        </TextLink>
                    ))
                )}
            </SubNavWrapper>
        </NavItemWrapper>
    );
};

export const DesktopNav = () => {
    const theme = useContext(ThemeContext);
    const location = useLocation();

    return (
        <NavBar $display="desktop">
            {/*
                Use data-nosnippet to prevent Google from using the nav as a description.  Can only be applied to divs, sections or span tags
                https://developers.google.com/search/docs/crawling-indexing/robots-meta-tag#data-nosnippet-attr
            */}
            <TopLayoutContent data-nosnippet>
                <CompanyLogo
                    aria-label="Easy Agile Home"
                    to="/"
                    $color={theme.color.primary.contrastText}
                >
                    <Logo />
                </CompanyLogo>
                <TopLayoutRight>
                    {navLists.map(({ category, links }) => (
                        <NavItem
                            key={category}
                            heading={category}
                            links={links}
                        />
                    ))}
                    <NavItem heading="blog" link={BLOG_PATH} />
                    <NavItemWrapper className="nav-site-search-button nav-site-search-button-desktop">
                        <SearchBar />
                    </NavItemWrapper>
                    <ButtonWrapper>
                        {/^\/demo/.test(location.pathname) ? (
                            <NoWrapButton
                                href={ASK_QUESTION}
                                data-conversionid="nav-ask-question"
                                variation="lightOutline"
                            >
                                Ask a question
                            </NoWrapButton>
                        ) : (
                            <NoWrapButton
                                href={GET_DEMO}
                                data-conversionid="nav-get-demo"
                                variation="lightOutline"
                            >
                                Get a demo
                            </NoWrapButton>
                        )}
                    </ButtonWrapper>
                </TopLayoutRight>
            </TopLayoutContent>
        </NavBar>
    );
};

const NoWrapButton = styled(Button)`
    white-space: nowrap;
`;
