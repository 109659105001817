import React, {
    createContext,
    useContext,
    useState,
    Dispatch,
    SetStateAction,
} from "react";

type SetValue<T> = Dispatch<SetStateAction<T>>;

const SearchUIModeContext = createContext<[boolean, SetValue<boolean>]>([
    false,
    () => {
        /* this block intentionally left blank*/
    },
]);

export const SearchUIModeProvider = ({ children }) => {
    const [isSearchActive, setIsSearchActive] = useState(false);
    return (
        <SearchUIModeContext.Provider
            value={[isSearchActive, setIsSearchActive]}
        >
            {children}
        </SearchUIModeContext.Provider>
    );
};

/**
 * search mode is whether or not the search UI should be shown.
 *
 * @returns an array just like useState
 * @example
 * const [isSearchUIActive, setIsSearchUIActive] = useSearchUIMode()
 */
export const useSearchUIMode = (): [boolean, SetValue<boolean>] => {
    return useContext(SearchUIModeContext);
};
