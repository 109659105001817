import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import { useEventListener } from "../util/useEventListener";

type borderRadiusType = "s" | "m" | "l" | "xl";

enum AnimationStates {
    OPENING,
    CLOSING,
    STILL,
}

const ModalShadow = styled.div<{ animationState: AnimationStates }>`
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: hsla(0, 0%, 13%, 0.7);
    transition: opacity 500ms;
    isolation: isolate;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    ${(p) => {
        switch (p.animationState) {
            case AnimationStates.OPENING:
                return css`
                    animation: fadeIn 250ms forwards;
                `;
            case AnimationStates.CLOSING:
                return css`
                    animation: fadeOut 250ms forwards;
                `;
        }
    }}
`;

const ModalContainer = styled.div<{ borderRadius: borderRadiusType }>`
    max-height: 85vh;
    overflow-y: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 100;
    translate: -50% -50%;
    border-radius: ${(p) => p.theme.borderRadius[p.borderRadius]};
    border: 4px solid ${(p) => p.theme.color.primary.main};
`;

const CloseButton = styled.div<{ closeButtonBackground: boolean }>`
    ${(p) => p.closeButtonBackground && "background: hsla(0, 0%, 13%, 0.7);"}
    z-index: 1;
    position: absolute;
    right: 8px;
    top: 8px;
    height: 32px;
    width: 32px;
    cursor: pointer;
    border-radius: ${(p) => p.theme.borderRadius.s};
    color: ${(p) =>
        p.closeButtonBackground ? "white" : p.theme.sectionTheme.fontColor};

    svg {
        height: 100%;
        width: 100%;
    }
`;

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    label?: string;
    children: JSX.Element | JSX.Element[];
    borderRadius?: borderRadiusType;
    closeButtonBackground?: boolean;
}

export const Modal = ({
    isOpen,
    onClose,
    label,
    borderRadius = "s",
    closeButtonBackground,
    children,
}: ModalProps) => {
    const [animationState, setAnimationState] = useState<AnimationStates>(
        AnimationStates.STILL
    );

    const modalRef = useRef(null);

    const closeModal = () => {
        setAnimationState(AnimationStates.CLOSING);
        document.body.classList.remove("searchDimmerPreventScroll");
    };

    // Close the modal if the user clicks out.
    const clickListener = (e: React.MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(e.target))
            closeModal();
    };

    const handleAnimationEnd = () => {
        if (animationState === AnimationStates.STILL) return;

        if (animationState === AnimationStates.CLOSING) onClose();
        setAnimationState(AnimationStates.STILL);
    };

    // Handle the escape key
    const keyPressedHandler = (e: KeyboardEvent) => {
        if (e.key === "Escape") closeModal();
    };

    // Use this to allow for animations in when isOpen changes
    useEffect(() => {
        if (isOpen) {
            setAnimationState(AnimationStates.OPENING);
            document.body.classList.add("searchDimmerPreventScroll");
        } else {
            document.body.classList.remove("searchDimmerPreventScroll");
        }
    }, [isOpen]);

    useEventListener("keydown", keyPressedHandler);

    borderRadius = borderRadius || "s";

    if (!isOpen) return null;

    return (
        <ModalShadow
            onClick={clickListener}
            onAnimationEnd={handleAnimationEnd}
            animationState={animationState}
        >
            <ModalContainer
                ref={modalRef}
                tabIndex={0}
                aria-label={label}
                borderRadius={borderRadius}
            >
                <CloseButton
                    closeButtonBackground={closeButtonBackground}
                    onClick={closeModal}
                >
                    <MdClose />
                </CloseButton>
                {children}
            </ModalContainer>
        </ModalShadow>
    );
};
