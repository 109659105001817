import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import { Hyperlink } from "../Link/Hyperlink";
import { SearchResultCategory } from "./const";
import {
    ListHeader,
    ListHeaderWrapper,
    HitsList,
    SeeMore,
    ResultsPill,
    SearchHit,
} from "./SearchHit";

export const DefaultProductHits = () => {
    const data = useStaticQuery(graphql`
        query {
            products: allSanityProduct(sort: { fields: atlassianProductId }) {
                nodes {
                    appId: atlassianProductId
                    icon {
                        asset {
                            url
                        }
                    }
                    title
                    productPage {
                        slug {
                            current
                        }
                    }
                    productListing {
                        title
                    }
                }
            }
        }
    `);

    const productNodes = data.products.nodes || [];
    const productData = productNodes
        .map((product) => {
            return {
                objectID: `/products/${product?.productPage?.slug?.current}`,
                slug: `/products/${product?.productPage?.slug?.current}`,
                title: product?.title,
                searchResultCategory: SearchResultCategory.Product,
                media: product?.icon?.asset?.url,
            };
        })
        .slice(0, 3);

    return (
        <HitsList>
            <ListHeaderWrapper>
                <ListHeader as="h3">
                    {productData[0].searchResultCategory}
                </ListHeader>
                <ResultsPill>4</ResultsPill>
            </ListHeaderWrapper>
            {productData.map((hit, index) => (
                <SearchHit hit={hit} key={`hit${index}`}></SearchHit>
            ))}
            <Hyperlink href="/products/">
                <SeeMore>See more</SeeMore>
            </Hyperlink>
        </HitsList>
    );
};
