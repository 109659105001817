import { ROUTE_CHANGE_EVENT_NAME } from "../constants";

type GatsbyLocation = {
    pathname: string;
};

interface GatsbyRouteUpdateParams {
    location: GatsbyLocation;
    prevLocation: GatsbyLocation | null;
}

export type RouteChangeEvent = CustomEvent<GatsbyRouteUpdateParams>;
export type OnRouteChangeCallback = (ev: RouteChangeEvent) => void;
export type OnRouteChangeDestroyer = () => void;

/**
 * Run a function when user navigates to a new page.
 *
 * @param {OnRouteChangeCallback} callback - Function to run on page change.
 * Receives a `RouteChangeEvent` as its first param with before/after route info.
 * @returns {OnRouteChangeDestroyer} Destroyer function which cancels the event listener.
 *
 * @example
 * ```tsx
 * useEffect(() => {
 *     const destroyer = onRouteChange((ev) => {
 *         console.log(ev.detail.location.pathname)
 *     });
 *     return () => destroyer;
 * });
 * ```
 */
export const onRouteChange = (
    callback: OnRouteChangeCallback
): OnRouteChangeDestroyer => {
    window.addEventListener(ROUTE_CHANGE_EVENT_NAME, callback);

    return () => window.removeEventListener(ROUTE_CHANGE_EVENT_NAME, callback);
};

const generateRouteChangeEvent = (params: GatsbyRouteUpdateParams) =>
    new CustomEvent(ROUTE_CHANGE_EVENT_NAME, {
        detail: params as GatsbyRouteUpdateParams,
    });

// Used in gatsby-browser.js
/**
 * @private
 * Should only be used in `gatsby-browser.js` to hook into Gatsby's Route API.
 * Not for use anywhere else.
 */
export const dispatchRouteChangeEvent = (params: GatsbyRouteUpdateParams) => {
    dispatchGatsbyEvent(generateRouteChangeEvent(params));
};

/**
 * @private
 * Dispatch an event in response to a Gatsby Browser API hook (gatsby-browser.js).
 * Use this instead of `window.dispatchEvent` to ensure event is dispatched only
 * after Gatsby hook has 100% finished running.
 * ---
 * @remarks
 * Internally, Gatsby wraps a lot of events in timeouts. It's not really
 * documented and requires digging into the guts of their codebase to find.
 * This causes a conflict if we want to run code that also happens to be
 * wrapped in a timeout in response to the event. To get around this
 * we'll wrap our event dispatcher in a couple of timeouts.
 *
 * Example: Segment's `analytics.page()` makes an API request after a `setTimeout`
 * call. We want to run this on page change, so we'll want to call it within
 * Gatsby's `onRouteUpdate` hook. Because of the timeout issues, `analytics.page()`
 * would be called _before_ the hook has resolved, it wouldn't have the
 * updated current page, and would report back to Segment's API that the user
 * has just navigated to the page which they actually just came from. Not good.
 *
 * Some example Gatsby code - the `onRouteUpdate` hook...
 * - [`onRouteUpdate` calls `apiRunner`](https://github.com/gatsbyjs/gatsby/blob/98c6c25dd7/packages/gatsby/cache-dir/navigation.js#L44-L44)...
 * - [`apiRunner` calls `loadPage`](https://github.com/gatsbyjs/gatsby/blob/98c6c25dd7/packages/gatsby/cache-dir/api-runner-browser.js#L23)...
 * - [...which ends up triggering timeouts](https://github.com/gatsbyjs/gatsby/blob/98c6c25dd7/packages/gatsby/cache-dir/loader.js#L446)
 */
const dispatchGatsbyEvent = (event: Event) => {
    setTimeout(() => {
        setTimeout(() => window.dispatchEvent(event));
    });
};
