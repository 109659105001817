/* tslint:disable:object-literal-sort-keys */

import { DeepPartial } from "ts-essentials";
import { merge, cloneDeep } from "lodash";
import { gridSquares, GRID_SQUARE } from "./grid";
import {
    GRID_COLUMN_WIDTH,
    GRID_COLUMN_GAP,
    GRID_COLUMN_AMOUNT,
} from "../constants/theming";
import { calcWidth } from "../util/theming";
import { pageThemes, ThemeColors } from "./pageThemes";

export const MEDIA_QUERY_TABLET_SMALL = 864;

const PARAGRAPH_MAX_WIDTH = gridSquares(35);
const PARAGRAPH_MAX_WIDTH_MEDIUM = gridSquares(45);
const GRID_GUTTERS_SMALL = gridSquares(1);

const defaultHeadingStyles = {
    lineHeight: "1.15em",
    letterSpacing: "0em",
    marginTop: "1.6em",
    marginBottom: "0.8em",
};

const defaultTheme = {
    /** Theme applies to viewports at `{viewportMinWidth}`px and above */
    viewportMinWidth: 0,
    // Just in case no page or section themes are specified, specify some.
    pageTheme: undefined,
    sectionTheme: {
        backgroundColor: ThemeColors.white,
        fontColor: ThemeColors.black,
        defaultButton: pageThemes.calming.defaultButton,
        accentButton: pageThemes.calming.accentButton,
        inputBorder: ThemeColors.deepViolet,
    },
    borderRadius: {
        /** XL: 40px */
        xl: `40px`,
        /** Large: 16px */
        l: `16px`,
        /** Medium: 8px */
        m: `8px`,
        /** Small: 4px */
        s: `4px`,
    },
    buttonSize: {
        s: "9px 18px",
        l: "18px 32px",
    },
    boxShadow: {
        xs: `
        0 1px 3px rgba(0,0,0,0.12),
        0 1px 2px rgba(0,0,0,0.24)
        `,
        s: `
        0 1px 1px rgba(0,0,0,0.11),
        0 2px 2px rgba(0,0,0,0.11),
        0 4px 4px rgba(0,0,0,0.11),
        0 6px 8px rgba(0,0,0,0.11),
        0 8px 16px rgba(0,0,0,0.11)
        `,
    },
    color: {
        /** Primary - deep violet (dark purple) - #330066 */
        primary: {
            main: "#330066",
            contrastText: "#ffffff",
        },
        /** Secondary 1 - meteor shower (bright purple) - #6633FF */
        secondaryOne: {
            main: "#6633FF",
            contrastText: "#ffffff",
        },
        /** Secondary 2 - flaming flamingo (bright pink) - #E55CFF */
        secondaryTwo: {
            main: "#E55CFF",
            contrastText: "#ffffff",
        },
        /** Accent 1 - pompelmo (dull red) - #FF6666 */
        accentOne: {
            main: "#FF6666",
            contrastText: "#ffffff",
        },
        /** Accent 2 - bright turquoise (...bright turquoise) - #0AEBD2 */
        accentTwo: {
            main: "#0AEBD2",
            contrastText: "#330066",
        },
        /** Error - pompelmo (dull red) - #FF6666 */
        error: {
            main: "#FF6666",
            contrastText: "#000000",
        },
        background: {
            default: "#ffffff",
            dim: "#EDEDED",
            contrastText: "#000000",
            subtleText: "#4d4d4d",
        },
        divider: {
            main: "#bdbdbd",
            subtle: "#f3f3f3",
        },
        card: {
            background: "#ffffff",
            lightWite: "#fafafa",
            dimBackground: "#EDEDED",
            contrastText: "#000000",
            subtleText: "#4d4d4d",
        },
        temp: {
            utilityBlack: "#000000",
            utilityWhite: "#ffffff",
            buttonHoverBackground: "#EEEEEE",
        },
        products: {
            storymaps: "#0AEBD2", // Bright Turquoise
            teamrhythm: "#0AEBD2",
            programs: "#E55CFF", // Flaming Flamingo
            roadmaps: "#6633ff", // Meteor Shower
            personas: "#FF6666", // Pompelmo
        },
        productsText: {
            storymaps: "#330066", // primary.main
            teamrhythm: "#330066", // primary.main
            programs: "#330066", // primary.main
            roadmaps: "#ffffff", // primary.contrastText
            personas: "#330066", // primary.main
        },
        pillButton: {
            // products page pill button
            main: "#EDEDED",
            contrastText: "#330066",
        },
        focus: "#6633ff", // Meteor Shower
    },
    fontFamily: {
        alternate: "avenir, sans-serif",
    },
    fontStyle: {
        normal: "normal",
        italic: "italic",
    },
    fontWeight: {
        normal: 400,
        medium: 500,
        bold: 700,
        heavy: 700, // TODO: Replace all instances with bold
    },
    /**
     * @deprecated Use theme.typography.
     *
     * First version of website rebrand, values are a little bit wrong.
     */
    fontSize: {
        /** @deprecated Use theme.typography - 84px */
        heading1: "84px",
        /** @deprecated Use theme.typography - 62px */
        heading2: "62px",
        /** @deprecated Use theme.typography - 48px */
        heading3: "48px",
        /** @deprecated Use theme.typography - 34px */
        heading4: "34px",
        /** @deprecated Use theme.typography - 24px */
        heading5: "24px",
        /** @deprecated Use theme.typography - 20px */
        heading6: "20px",
        /** @deprecated Use theme.typography - 18px */
        bodyText: "18px",
        /** Global button text size for button component: 16px */
        button: "16px",
        pillButton: "11px",
        mainTitle: "56.4972px",
        headingExtraLarge: "39.548px",
        headingLarge: "33.8983px",
        headingMedium: "28.2486px",
        headingSmall: "22.5989px",
        regularLarge: "19.774px",
        regular: "16.9492px",
        regularSmall: "14.1243px",
        // Herobanner font sizes
        heroBannerTitle: "32px",
        heroBannerSubtitle: "22px",
        heroBannerSubtitleSm: "18px",
        heroBannerTitleMd: "52px",
        heroBannerTitleXl: "62px",
    },
    typography: {
        h1: {
            ...defaultHeadingStyles,
            fontSize: "32px",
            fontWeight: 700,
        },
        h2: {
            ...defaultHeadingStyles,
            fontSize: "30px",
            fontWeight: 700,
        },
        h3: {
            ...defaultHeadingStyles,
            fontSize: "28px",
            fontWeight: 700,
        },
        h4: {
            ...defaultHeadingStyles,
            fontSize: "24px",
            fontWeight: 700,
        },
        h5: {
            ...defaultHeadingStyles,
            fontSize: "22px",
            fontWeight: 500,
        },
        h6: {
            ...defaultHeadingStyles,
            fontSize: "18px",
            fontWeight: 700,
        },
        body: {
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "1.4em",
            letterSpacing: "0em",
            marginTop: "1.6em",
            marginBottom: "0.8em",
        },
        disclaimer: {
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "1.4em",
            letterSpacing: "0em",
            marginTop: "1.6em",
            marginBottom: "0.8em",
        },
        linkButton: {
            fontSize: "16px",
            fontWeight: 700,
            letterSpacing: "0em",
            marginTop: "1.6em",
            marginBottom: "0.8em",
        },
    },
    grid: {
        gutters: {
            l: gridSquares(5),
            m: gridSquares(3),
            s: GRID_GUTTERS_SMALL,
        },
        squareNumOfPx: GRID_SQUARE,
        columnWidth: GRID_COLUMN_WIDTH,
        columnGap: GRID_COLUMN_GAP,
        columnAmount: GRID_COLUMN_AMOUNT,
    },
    /** @deprecated Use `theme.viewport` */
    breakpoint: {
        // Values are the min width/lower values for each breakpoint.
        // Use them like @media(min-width: p.theme.breakpoint.sm).
        // Got these values from Bootstrap: https://getbootstrap.com/docs/5.1/layout/breakpoints/
        /** Extra small (mobile) = 0-575px */
        xs: 0,
        /** Small (large mobile) = 576-767px */
        sm: 576,
        /** Medium (small tablet) = 768-991px */
        md: 768,
        /** Large (tablet): 992-1199px */
        lg: 992,
        /** Extra large (small laptop): 1200-1399px */
        xl: 1200,
        /** Extra extra large: 1400-1999px */
        xxl: 1400,
        /** >=2000 */
        xxxl: 2000,
    },
    height: {
        navBar: `90px`,
        promoStrip: `45px`,
    },
    maxWidth: {
        pageSection: `calc(${PARAGRAPH_MAX_WIDTH} + 2 * ${GRID_GUTTERS_SMALL})`,
        paragraph: PARAGRAPH_MAX_WIDTH,
        paragraphMedium: PARAGRAPH_MAX_WIDTH_MEDIUM,
        teamMembersSection: gridSquares(51),
        pageSectionContainer: calcWidth(10),
    },
    mediaQuery: {
        articleTile1Column: `700px`,
    },
    opacity: {
        disabled: 0.4,
    },
    padding: {
        pageSide: GRID_GUTTERS_SMALL,
    },
    transition: (properties: string) => `${properties} 150ms ease-in`,
};

type BaseTheme = typeof defaultTheme;

export type ViewportSize = "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "xxxl";

const createThemeVariant = (
    baseTheme: BaseTheme,
    variantTheme: ViewportSizeTheme
) => {
    return merge(cloneDeep(baseTheme), variantTheme);
};

const smTheme = createThemeVariant(defaultTheme, {
    viewportMinWidth: 576,
});

const mdTheme = createThemeVariant(smTheme, {
    viewportMinWidth: 768,
    typography: {
        h1: {
            fontSize: "52px",
        },
        h2: {
            fontSize: "38px",
        },
        h3: {
            fontSize: "34px",
        },
        h4: {
            fontSize: "32px",
        },
        h5: {
            fontSize: "24px",
        },
        h6: {
            fontSize: "18px",
        },
    },
});

const lgTheme = createThemeVariant(mdTheme, {
    viewportMinWidth: 992,
});

const xlTheme = createThemeVariant(lgTheme, {
    viewportMinWidth: 1200,
    typography: {
        h1: {
            fontSize: "64px",
        },
        h2: {
            fontSize: "48px",
        },
        h3: {
            fontSize: "40px",
        },
        h4: {
            fontSize: "36px",
        },
        h5: {
            fontSize: "24px",
        },
        h6: {
            fontSize: "20px",
        },
    },
});

const xxlTheme = createThemeVariant(xlTheme, {
    viewportMinWidth: 1400,
});

const xxxlTheme = createThemeVariant(xxlTheme, {
    viewportMinWidth: 2000,
});

type ViewportSizeTheme = {
    viewportMinWidth: number;
} & DeepPartial<BaseTheme>;

type Theme = BaseTheme & {
    viewport: {
        [K in ViewportSize]: ViewportSizeTheme;
    };
    media(viewportSize: ViewportSize): string;
};

// Viewport theme variants inherit all
export const theme: Theme = {
    ...defaultTheme,
    viewport: {
        /** Extra Small: 576px - 767px */
        xs: defaultTheme,
        /** Small: 576px - 767px */
        sm: smTheme,
        /** Medium: 768px - 991px */
        md: mdTheme,
        /** Large: 992px - 1199px */
        lg: lgTheme,
        /** XL: 1200px - 1399px */
        xl: xlTheme,
        /** XXL: 1400px - 1999px */
        xxl: xxlTheme,
        /** >=2000 */
        xxxl: xxxlTheme,
    },
    /**
     * @method media
     *
     * Helper function for constructing media queries for various breakpoints.
     * The media query will target the *specified breakpoint and above*.
     * This means that you don't need to use JS to toggle styles on and off
     * for each breakpoint - you simply need to write your styles in the right
     * order. Each successive breakpoint will "stack", so whichever is written
     * last (and is currently active) will win.
     *
     * @returns CSS media query string for the specified viewport size.
     *
     * @example
     * ```tsx
     * const CoolText = styled.p`
     *     font-size: 14px; // Mobile
     *
     *     ${(p) => p.theme.media("md")} {
     *         font-size: 18px; // Tablet
     *     }
     *
     *     ${(p) => p.theme.media("xxl")} {
     *         font-size: 24px; // Desktop
     *     }
     * `;
     * ```
     */
    media(viewportSize: ViewportSize) {
        return `@media screen and (min-width: ${this.viewport[viewportSize].viewportMinWidth}px)`;
    },
};
