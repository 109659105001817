import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { Analytics, AnalyticsBrowser } from "@segment/analytics-next";
import { SEGMENT_WRITE_KEY } from "../constants";
import { onRouteChange, RouteChangeEvent } from "./events";
import { isProductMarketplaceUrl } from "./link";
import {
    ApplySessionUTM,
    GetSessionUTM,
    GetFirstUTMData,
} from "../components/SessionUTMStorage";

const GetRevealData = () => {
    // Clearbit reveal data data is hooked up via Google Tag Manager
    if (typeof window === undefined) return { test: "nothydrated" };
    // @ts-expect-error
    const reveal = window.reveal;
    if (!reveal) return { test: "noreveal" };

    return {
        companyAlexaRank: reveal?.company?.metrics?.alexaGlobalRank,
        companyCity: reveal?.company?.geo?.city,
        companyCountry: reveal?.company?.geo?.country,
        companyDomain: reveal?.company?.domain,
        companyEmployees: reveal?.company?.metrics?.employees,
        companyEmployeesRange: reveal?.company?.metrics?.employeesRange,
        companyEstAnnualRevenue:
            reveal?.company?.metrics?.estimatedAnnualRevenue,
        companyFunding: reveal?.company?.metrics?.raised,
        companyIndustry: reveal?.company?.category?.industry,
        companyIndustryGroup: reveal?.company?.category?.industryGroup,
        companyNaicsCode: reveal?.company?.category?.naicsCode,
        companyName: reveal?.company?.name,
        companySector: reveal?.company?.category?.sector,
        companySicCode: reveal?.company?.category?.sicCode,
        companyState: reveal?.company?.geo?.state,
        companySubIndustry: reveal?.company?.category?.subindustry,
        companyType: reveal?.company?.type,
        revealTrafficType: reveal?.type,
    } as const;
};
const SegmentContext = createContext<Analytics | undefined>(undefined);

/**
 * Wraps the whole page and provides an instance of Segment Analytics.
 * You can use the `useSegment()` hook in any component nested
 * anywhere within `SegmentProvider` - i.e, pretty much the whole app.
 * `useSegment()` will return the Segment Analytics instance from this provider.
 *
 * @see [Segment docs](https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/)
 * @see [@segment/analytics-next docs](https://github.com/segmentio/analytics-next/)
 *
 * @remarks
 * We use the `@segment/analytics-next` NPM package, which wraps their `analytics.js`
 * script. Nicer dev experience than just dumping a `<script>` on the page.
 */
export const SegmentProvider = ({ children }) => {
    const [segment, _setSegment] = useState<Analytics | undefined>(undefined);
    const [segmentWriteKey, setSegmentWriteKey] = useState(SEGMENT_WRITE_KEY);
    // Array of event listener destroyer functions to run when component is destroyed.
    type EventListenerDestroyer = () => void;
    const [eventListeners, setEventListeners] = useState<
        EventListenerDestroyer[]
    >([]);

    // Event listeners can't reliably access state between component renders.
    // Using React refs to get around that.
    // Good article: https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559
    // Docs: https://reactjs.org/docs/refs-and-the-dom.html
    const segmentRef = useRef(segment);
    const setSegment = (newSegment) => {
        segmentRef.current = newSegment;
        _setSegment(newSegment);
    };

    useEffect(() => {
        if (!segmentWriteKey) {
            console.warn("Not loading Segment - no write key found.");
            return;
        }

        const loadSegment = async () => {
            const [_segment] = await AnalyticsBrowser.load({
                writeKey: segmentWriteKey,
            });

            setSegment(_segment);

            trackEventListener(onRouteChange(sendPageEvent));

            const firstUTM = GetFirstUTMData() || {};
            const revealData = GetRevealData();

            _segment.page(
                undefined /*optional argument - use default page*/,
                undefined /*optional argument - use default name*/,
                {
                    referrer: document.referrer,
                    sessionUTM: GetSessionUTM(),
                    ...firstUTM,
                    ...revealData,
                }
            ); // Send page load event on initial page load
        };

        loadSegment();

        return () => destroyEventListeners();
    }, [segmentWriteKey]);

    const sendPageEvent = (ev: RouteChangeEvent) => {
        // Need to use ref instead of state in event listeners.
        const _segment = segmentRef.current;

        // Segment hasn't loaded yet or event doesn't have enough data
        if (!_segment || !ev.detail.location?.pathname) {
            return;
        }

        // Don't send an event if user has clicked a link to the
        // page they're already on.
        // prevLocation may be null if this is the first page loaded.
        if (ev.detail.location.pathname === ev.detail.prevLocation?.pathname) {
            return;
        }

        _segment.page();
    };

    const trackEventListener = (destroyer: EventListenerDestroyer) => {
        setEventListeners([...eventListeners, destroyer]);
    };

    const destroyEventListeners = () => {
        eventListeners.forEach((destroyCallback) => {
            destroyCallback();
        });
    };

    return (
        <SegmentContext.Provider value={segment}>
            {children}
        </SegmentContext.Provider>
    );
};

/**
 * Get the main Segment Analytics instance for the app. This is retrieved from
 * the `SegmentProvider` context provider which wraps the whole app.
 *
 * **Note:** May return undefined until Segment finishes initializing. You must
 * handle that case when using this.
 *
 * @see [Segment docs](https://segment.com/docs/)
 *
 * @example
 * ```tsx
 * const MyComponent = () => {
 *     const segment = useSegment();
 *
 *     if (segment) {
 *         return <p>Your ID is: {segment.user().anonymousId}</p>;
 *     }
 *
 *     return <p>Still loading Segment!</p>
 * };
 * ```
 */
export const useSegment = (): Analytics | undefined => {
    return useContext(SegmentContext);
};

const getSegmentAnonId = (segment: Analytics): string | undefined => {
    return segment?.user().anonymousId();
};

// Query parameter appended to Atlassian Marketplace links for Segment tracking
const marketplaceAnonIdQueryParam = "partner_anonymous_id";

/**
 * Adds Segment-specific query params to a URL for tracking purposes. The only
 * param added right now is `partner_anonymous_id` on Atlassian Marketplace links.
 *
 * @param {string} url - The URL to append the query params to.
 * @param {Analytics} segment - Segment Analytics instance. You can get this with the `useSegment()` hook.
 * @returns {string} The modified URL. Eg - https://example.com?partner_anonymous_id=abcd1234
 *
 * @example
 * ```tsx
 * const CoolLink = () => {
 *     const segment = useSegment();
 *     let url = addSegmentQueryParams("https://example.com", segment);
 *     return <a href={url}>Click me</a>;
 * };
 * ```
 */
export const addSegmentQueryParams = (
    url: string,
    segment: Analytics
): string => {
    if (!url || !segment || !isProductMarketplaceUrl(url)) {
        return url;
    }

    // if it's going to the marketplace, add partner_anonymous_id, the GCLID, any utm_ parameter
    // so that we can do source attribution in the marketplace GA4 + segment
    // GCLIDs will help us measure conversions on any google ad raised.

    const anonId = getSegmentAnonId(segment);
    const urlObj = ApplySessionUTM(new URL(url));
    urlObj.searchParams.set(marketplaceAnonIdQueryParam, anonId);

    return urlObj.href;
};
