import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { calcWidth } from "../util/theming";
import { Heading } from "./Typography/Heading";
import { MdChevronRight } from "@react-icons/all-files/md/MdChevronRight";
import { Hyperlink } from "./Link/Hyperlink";
import { RenderImage } from "./RenderImage";

const ParentLayout = styled.div`
    width: 100%;
    background-color: ${(p) => p.theme.color.secondaryOne.main};
    color: ${(p) => p.theme.color.secondaryOne.contrastText};
    height: ${(p) => p.theme.height.promoStrip};

    transition: background 120ms;

    :hover {
        background-color: ${(p) => p.theme.color.secondaryTwo.main};
    }
`;

const Inner = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 32px;
    height: ${(p) => p.theme.height.promoStrip};
    max-width: ${calcWidth(12)}px;
    margin-left: 30px;
    margin-right: 30px;

    ${(p) => p.theme.media("md")} {
        margin-left: 48px;
        margin-right: 48px;
    }

    ${(p) => p.theme.media("lg")} {
        margin-left: 96px;
        margin-right: 96px;
    }

    ${(p) => p.theme.media("xl")} {
        margin-left: 128px;
        margin-right: 128px;
    }

    ${(p) => p.theme.media("xxxl")} {
        margin: auto;
    }
`;

const InnerText = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 3em;
    flex: 1 0 auto;
`;

const Title = styled(Heading)`
    margin-bottom: 0px;
`;

const Description = styled.p`
    display: none;

    ${(p) => p.theme.media("xxl")} {
        display: block;
    }
`;

const CTAText = styled(Heading)`
    margin-bottom: 0px;
    text-transform: uppercase;
    display: none;

    ${(p) => p.theme.media("md")} {
        display: block;
    }
`;

const CTA = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    flex-shrink: 0;
    gap: 2px;
    align-items: center;

    > :last-child {
        transition: ${(p) => p.theme.transition("transform")};
    }

    :hover {
        > :last-child {
            transform: translateX(0.5em);
        }
    }
`;

const ImageContainer = styled.div`
    display: none;
    flex-shrink: 1;

    ${(p) => p.theme.media("sm")} {
        display: block;
    }
`;

export const PromoTeaser = () => {
    const data = useStaticQuery(
        graphql`
            query {
                allSanityPromoStripSettings {
                    nodes {
                        ctaText
                        ctaUrl
                        visible
                        title
                        description
                        backgroundImage {
                            lottieFile {
                                asset {
                                    url
                                }
                            }
                            imageType
                            image {
                                asset {
                                    gatsbyImageData(
                                        placeholder: NONE
                                        layout: FULL_WIDTH
                                    )
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    const promoTeaserSettings = data.allSanityPromoStripSettings.nodes[0];
    const backgroundImageMeta = promoTeaserSettings.backgroundImage;

    if (!promoTeaserSettings.visible) {
        return null;
    }

    return (
        <ParentLayout>
            <Hyperlink href={promoTeaserSettings.ctaUrl}>
                <Inner>
                    <InnerText>
                        <Title level="h5" forwardedAs="p">
                            {promoTeaserSettings.title}
                        </Title>
                        <Description>
                            {promoTeaserSettings.description}
                        </Description>
                    </InnerText>
                    <ImageContainer>
                        <RenderImage
                            loading="lazy"
                            imageData={backgroundImageMeta}
                        />
                    </ImageContainer>
                    <CTA>
                        <CTAText level="h6" forwardedAs="p">
                            {promoTeaserSettings.ctaText}
                        </CTAText>
                        <MdChevronRight />
                    </CTA>
                </Inner>
            </Hyperlink>
        </ParentLayout>
    );
};
