import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import { SearchResultCategory } from "./const";
import { Hyperlink } from "../Link/Hyperlink";
import {
    ListHeader,
    ListHeaderWrapper,
    HitsList,
    SeeMore,
    ResultsPill,
    SearchHit,
} from "./SearchHit";

export const DefaultBlogHits = () => {
    const data = useStaticQuery(graphql`
        query {
            blogs: allSanityHomePageSettings {
                nodes {
                    blogPosts {
                        blogPosts {
                            title
                            slug {
                                current
                            }
                            featuredImage {
                                img {
                                    asset {
                                        url
                                    }
                                }
                            }
                            category {
                                name
                            }
                            _type
                        }
                    }
                }
            }
        }
    `);

    const blogNodes = data.blogs.nodes[0] || [];
    const blogPosts = blogNodes?.blogPosts?.blogPosts || [];
    const blogData = blogPosts.map((blog) => {
        return {
            objectID: `/blog/${blog.slug.current}`,
            slug: `/blog/${blog.slug.current}`,
            title: blog.title,
            searchResultCategory: SearchResultCategory.BlogPost,
            media: blog?.featuredImage?.img?.asset?.url,
        };
    });

    return (
        <HitsList>
            <ListHeaderWrapper>
                <ListHeader as="h3">
                    {blogData[0].searchResultCategory}
                </ListHeader>
                <ResultsPill>99+</ResultsPill>
            </ListHeaderWrapper>
            {blogData.map((hit, index) => (
                <SearchHit hit={hit} key={`hit${index}`}></SearchHit>
            ))}
            <Hyperlink href="/blog/">
                <SeeMore>See more</SeeMore>
            </Hyperlink>
        </HitsList>
    );
};
