import React from "react";

export const Logo = () => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 502.74 128"
            style={{ enableBackground: "new 0 0 502.74 128" }}
            xmlSpace="preserve"
        >
            <style
                type="text/css"
                dangerouslySetInnerHTML={{
                    __html: "\n\t.st0{fill:currentColor;}\n",
                }}
            />
            <g>
                <g>
                    <polygon
                        className="st0"
                        points="144.5,83.52 144.5,29.96 180.51,29.96 180.51,40.85 156.98,40.85 156.98,50.99 179.22,50.99
			179.22,61.35 156.98,61.35 156.98,72.47 181.87,72.47 181.87,83.52 		"
                    />
                    <g>
                        <path
                            className="st0"
                            d="M211.67,67.1h-1.59c-1.36,0-2.74,0.06-4.12,0.19c-1.39,0.13-2.62,0.37-3.71,0.72
				c-1.09,0.36-1.98,0.87-2.68,1.55c-0.71,0.68-1.06,1.58-1.06,2.68c0,0.71,0.16,1.31,0.49,1.82c0.33,0.5,0.75,0.91,1.25,1.21
				c0.5,0.31,1.08,0.52,1.74,0.65c0.66,0.13,1.29,0.19,1.89,0.19c2.52,0,4.45-0.69,5.79-2.08c1.34-1.39,2-3.27,2-5.64V67.1z
				 M188.9,50.99c2.22-2.12,4.8-3.71,7.75-4.77c2.95-1.06,5.96-1.59,9.04-1.59c3.18,0,5.86,0.39,8.06,1.17
				c2.19,0.78,3.97,1.99,5.33,3.63c1.36,1.64,2.36,3.71,2.99,6.2c0.63,2.5,0.95,5.46,0.95,8.89v18.99h-11.35V79.5h-0.23
				c-0.96,1.57-2.41,2.78-4.35,3.63c-1.94,0.86-4.05,1.29-6.32,1.29c-1.52,0-3.08-0.2-4.69-0.61c-1.61-0.4-3.09-1.06-4.42-1.97
				c-1.34-0.91-2.43-2.12-3.29-3.63c-0.86-1.52-1.29-3.38-1.29-5.6c0-2.72,0.74-4.92,2.23-6.58c1.49-1.67,3.4-2.95,5.75-3.86
				c2.34-0.91,4.95-1.52,7.83-1.82c2.87-0.3,5.67-0.45,8.4-0.45v-0.6c0-1.87-0.66-3.24-1.97-4.13c-1.31-0.88-2.92-1.32-4.84-1.32
				c-1.77,0-3.47,0.38-5.11,1.14c-1.63,0.76-3.03,1.66-4.2,2.72L188.9,50.99z"
                        />
                        <path
                            className="st0"
                            d="M253.94,56.97c-1.01-0.96-2.19-1.76-3.55-2.42c-1.36-0.66-2.8-0.98-4.31-0.98c-1.16,0-2.23,0.23-3.21,0.68
				c-0.98,0.46-1.47,1.24-1.47,2.34c0,1.06,0.54,1.82,1.62,2.27c1.09,0.45,2.84,0.96,5.26,1.51c1.41,0.3,2.84,0.73,4.28,1.29
				c1.44,0.55,2.73,1.28,3.89,2.19c1.16,0.91,2.09,2.02,2.8,3.33c0.71,1.31,1.06,2.87,1.06,4.69c0,2.37-0.48,4.38-1.44,6.01
				c-0.96,1.64-2.21,2.96-3.74,3.97c-1.54,1.01-3.27,1.74-5.18,2.2c-1.92,0.45-3.83,0.68-5.75,0.68c-3.08,0-6.09-0.49-9.04-1.47
				c-2.95-0.98-5.41-2.43-7.37-4.35l6.96-7.34c1.11,1.21,2.47,2.22,4.08,3.03c1.61,0.81,3.33,1.21,5.14,1.21
				c1.01,0,2.01-0.24,2.99-0.72c0.99-0.48,1.48-1.33,1.48-2.54c0-1.16-0.6-2.02-1.81-2.57c-1.21-0.55-3.1-1.13-5.68-1.74
				c-1.31-0.31-2.62-0.71-3.93-1.21c-1.31-0.5-2.49-1.18-3.52-2.04c-1.03-0.86-1.88-1.92-2.54-3.18c-0.66-1.26-0.98-2.77-0.98-4.54
				c0-2.27,0.48-4.2,1.44-5.79c0.96-1.59,2.19-2.89,3.71-3.9c1.51-1.01,3.19-1.75,5.03-2.23c1.84-0.48,3.67-0.72,5.48-0.72
				c2.82,0,5.58,0.44,8.29,1.32c2.69,0.89,4.98,2.21,6.84,3.97L253.94,56.97z"
                        />
                        <path
                            className="st0"
                            d="M289.39,89.19c-0.75,1.97-1.6,3.72-2.53,5.26c-0.93,1.54-2.04,2.85-3.33,3.93c-1.29,1.08-2.8,1.9-4.54,2.46
				c-1.74,0.55-3.79,0.83-6.16,0.83c-1.16,0-2.36-0.07-3.59-0.22c-1.24-0.15-2.31-0.36-3.22-0.61l1.36-10.44
				c0.6,0.2,1.27,0.37,2,0.49c0.73,0.13,1.4,0.19,2,0.19c1.92,0,3.3-0.44,4.16-1.32c0.85-0.88,1.59-2.13,2.19-3.74l1.21-3.1
				l-15.74-37.07h13.77l8.4,24.2h0.23l7.49-24.2h13.16L289.39,89.19z"
                        />
                    </g>
                    <path
                        className="st0"
                        d="M341.61,44.26l-6.81,18.38h13.47L341.61,44.26z M355.98,83.52L351.82,73H331.1l-3.93,10.51h-14.07
			l22.47-53.56h12.56l22.24,53.56H355.98z"
                    />
                    <g>
                        <path
                            className="st0"
                            d="M401.27,64.45c0-1.26-0.22-2.47-0.64-3.63c-0.43-1.16-1.05-2.18-1.85-3.06c-0.81-0.89-1.76-1.58-2.87-2.08
				c-1.11-0.51-2.37-0.76-3.78-0.76c-1.41,0-2.67,0.25-3.75,0.76c-1.08,0.5-2,1.18-2.76,2.04c-0.75,0.85-1.34,1.87-1.74,3.03
				c-0.4,1.16-0.61,2.4-0.61,3.71c0,1.26,0.2,2.47,0.61,3.63c0.4,1.17,0.98,2.18,1.74,3.07c0.76,0.88,1.68,1.59,2.76,2.12
				s2.33,0.8,3.75,0.8c1.41,0,2.68-0.27,3.82-0.8c1.13-0.53,2.09-1.22,2.87-2.08c0.78-0.85,1.39-1.86,1.81-3.03
				C401.05,67,401.27,65.77,401.27,64.45 M413.07,79.96c0,3.68-0.53,6.88-1.59,9.61c-1.06,2.73-2.54,4.98-4.46,6.77
				c-1.91,1.79-4.25,3.12-6.99,4.01s-5.79,1.32-9.12,1.32c-3.33,0-6.74-0.5-10.25-1.51c-3.5-1.01-6.54-2.47-9.11-4.39l6.2-8.85
				c1.81,1.61,3.82,2.81,6.01,3.59c2.2,0.78,4.35,1.17,6.47,1.17c3.63,0,6.32-0.96,8.06-2.87c1.74-1.92,2.61-4.46,2.61-7.64V78.9
				h-0.23c-1.16,1.51-2.72,2.73-4.69,3.63c-1.97,0.91-4.21,1.36-6.73,1.36c-2.87,0-5.42-0.52-7.64-1.55s-4.1-2.43-5.63-4.2
				c-1.54-1.77-2.71-3.82-3.52-6.17c-0.81-2.35-1.21-4.83-1.21-7.45c0-2.62,0.4-5.12,1.21-7.49c0.81-2.37,1.98-4.46,3.52-6.27
				c1.53-1.82,3.41-3.27,5.63-4.35c2.22-1.09,4.74-1.62,7.56-1.62c2.37,0,4.64,0.48,6.81,1.44c2.16,0.96,3.91,2.45,5.22,4.46h0.15
				v-4.84h11.72V79.96z"
                        />
                        <path
                            className="st0"
                            d="M422.16,45.85h12.41v37.67h-12.41V45.85z M435.47,33.82c0,0.96-0.19,1.85-0.57,2.68
				c-0.38,0.83-0.88,1.55-1.52,2.15c-0.63,0.61-1.39,1.09-2.27,1.44c-0.88,0.36-1.8,0.53-2.76,0.53c-2.02,0-3.71-0.67-5.07-2
				c-1.36-1.34-2.04-2.94-2.04-4.8c0-0.91,0.18-1.78,0.53-2.61c0.35-0.83,0.85-1.55,1.51-2.16c0.66-0.6,1.41-1.1,2.27-1.47
				c0.85-0.38,1.79-0.57,2.8-0.57c0.96,0,1.88,0.17,2.76,0.53c0.88,0.35,1.64,0.83,2.27,1.44c0.63,0.6,1.14,1.32,1.52,2.15
				C435.29,31.96,435.47,32.86,435.47,33.82"
                        />
                    </g>
                    <rect
                        x="443.66"
                        y="26.33"
                        className="st0"
                        width="12.63"
                        height="57.18"
                    />
                    <path
                        className="st0"
                        d="M501.34,56.51c-0.93-2.52-2.23-4.66-3.9-6.43c-1.66-1.76-3.66-3.11-5.98-4.04c-2.32-0.93-4.87-1.4-7.64-1.4
			c-2.87,0-5.55,0.48-8.02,1.44c-2.47,0.96-4.61,2.31-6.43,4.05c-1.81,1.74-3.25,3.86-4.31,6.35c-1.06,2.5-1.59,5.29-1.59,8.36
			c0,3.18,0.54,6,1.62,8.47c1.08,2.47,2.57,4.55,4.46,6.24c1.89,1.69,4.09,2.97,6.62,3.86c2.52,0.88,5.2,1.32,8.02,1.32
			c3.83,0,7.2-0.69,10.1-2.08c2.9-1.39,5.23-3.37,7-5.94l-8.62-5.45c-0.86,1.16-1.97,2.11-3.33,2.84c-1.36,0.73-3.03,1.1-4.99,1.1
			c-1.11,0-2.18-0.18-3.22-0.53c-1.03-0.35-1.97-0.84-2.8-1.48c-0.83-0.63-1.51-1.37-2.04-2.23c-0.53-0.85-0.84-1.81-0.95-2.87
			h27.31c0.05-0.45,0.08-0.93,0.08-1.43v-1.52C502.74,61.91,502.27,59.03,501.34,56.51z M475.35,60.37c0.05-0.96,0.3-1.88,0.76-2.76
			c0.45-0.89,1.06-1.65,1.82-2.31c0.75-0.66,1.64-1.18,2.65-1.55c1.01-0.38,2.12-0.57,3.33-0.57c2.47,0,4.33,0.71,5.56,2.12
			c1.23,1.41,1.85,3.1,1.85,5.07H475.35z"
                    />
                    <path
                        className="st0"
                        d="M73.41,94.66c-5.09,2.44-10.38,3.65-15.86,3.65c-5.95,0-10.96-1.43-15.03-4.26
			c-4.07-2.84-6.11-7.27-6.11-13.27v-1.94c8.46,0,17.15-0.49,26.07-1.46c8.93-0.97,17.03-2.92,24.32-5.84
			c7.28-2.92,13.24-7.06,17.85-12.41c4.62-5.35,6.93-12.41,6.93-21.17c0-7.14-1.33-13.14-3.99-18.01
			c-2.66-4.86-6.07-8.76-10.22-11.67c-4.15-2.92-8.73-5.03-13.74-6.33C78.62,0.66,73.76,0,69.07,0c-7.05,0-13.59,1.38-19.61,4.14
			c-6.03,2.75-10.53,6.65-13.51,11.68h-0.7V2.92H0V64c0,11.03,0.98,20.56,2.94,28.59c1.96,8.03,5.05,14.69,9.28,19.96
			c4.23,5.27,9.75,9.17,16.56,11.68c6.81,2.52,15.15,3.77,25.02,3.77c9.55,0,18.91-1.7,28.07-5.11c9.16-3.41,17.19-8.52,24.08-15.33
			L86.45,85.9C82.85,89.31,78.5,92.23,73.41,94.66z M35.24,51.59c0-7.63,2.08-13.67,6.22-18.13c4.15-4.46,10.14-6.69,17.97-6.69
			c1.88,0,3.84,0.2,5.87,0.61c2.04,0.4,3.84,1.09,5.4,2.07c1.57,0.97,2.86,2.27,3.88,3.89c1.02,1.62,1.53,3.56,1.53,5.84
			c0,3.56-1.09,6.45-3.29,8.64c-2.19,2.19-4.97,3.85-8.34,4.99c-3.36,1.13-7.2,1.9-11.51,2.31c-4.31,0.4-8.57,0.61-12.8,0.61h-4.94
			V51.59z"
                    />
                </g>
            </g>
        </svg>
    );
};
