import styled, { css } from "styled-components";
import { gridSquares } from "../../styles/grid";

export const formStyles = css`
    width: 100%;
    max-width: ${gridSquares(26)};

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

export const Form = styled.form`
    ${formStyles}
`;
