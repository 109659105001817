import React, { useEffect } from "react";
import queryString from "query-string";
import { EA_WEBSITE_URL } from "../constants/urls";

export const GetSessionUTM = () => {
    if (typeof window === "undefined") return "";
    if (window.sessionStorage) {
        return window.sessionStorage.getItem("SessionUTM");
    }
    return "";
};

export const GetFirstUTMData = () => {
    if (typeof window === "undefined") return undefined;
    if (window.localStorage) {
        if (window.localStorage.getItem("FirstUTMTime")) {
            return {
                firstUTM: window.localStorage.getItem("FirstUTM"), // it's ok for this to be empty, that's a direct page hit.
                firstUTMTime: window.localStorage.getItem("FirstUTMTime"),
            };
        }
    }
    return undefined;
};

export const ApplySessionUTM = (url: URL): URL => {
    const sessionUTM = GetSessionUTM();
    if (sessionUTM && sessionUTM.length > 0) {
        // for purposes of parsing make https://easyagile.com/?sessionUTM
        const dummySessionUTMUrl = new URL(EA_WEBSITE_URL + "?" + sessionUTM);

        const sessionUTMValues = queryString.parse(dummySessionUTMUrl.search);
        const currentQueryStringValues = queryString.parse(url.search);

        // mix the query param values from the session and the current URL
        const combinedQueryParamValues = {
            ...sessionUTMValues,
            ...currentQueryStringValues,
        };

        const newURL = new URL(url);
        Object.keys(combinedQueryParamValues).forEach((queryParam) => {
            newURL.searchParams.set(
                queryParam,
                combinedQueryParamValues[queryParam] as string
            );
        });
        return newURL;
    }

    return url;
};

/**
 * This is a dummy node that sits in the react tree and stores any UTM string
 * that was originally passed in the session.  Other elements can pass this as
 * a referrer using the GetSessionUTM() api.   Things returned will be GCLIDs
 * and utm parameters.  This will allow for the conversion on third party sites
 * like BigMarker.
 *
 * @returns empty element
 */
export const SessionUTMStorage = () => {
    useEffect(() => {
        if (typeof window !== "undefined") {
            const queryParams = queryString.parse(window.location.search);
            if (window.sessionStorage) {
                const trackingData = {
                    utm_source: queryParams.utm_source, // social network, etc, driving campaign
                    utm_medium: queryParams.utm_medium, // type of chanel driving campaign.  is it paid? is it an email open?
                    utm_campaign: queryParams.utm_campaign, // campaign name
                    utm_content: queryParams.utm_content, // content - track diff ads in a campaign
                    gclid: queryParams.gclid, // google ads click id
                } as const;

                if (!window.sessionStorage.getItem("SessionUTM")) {
                    let trackingUTM = "";
                    Object.keys(trackingData).forEach((trackingParam) => {
                        if (trackingData[trackingParam]) {
                            trackingUTM += `&${trackingParam}=${trackingData[trackingParam]}`;
                        }
                    });
                    // save this to session storage so we can remember attribution
                    if (trackingUTM.length > 0) {
                        window.sessionStorage.setItem(
                            "SessionUTM",
                            trackingUTM
                        );
                        window.dispatchEvent(new Event("restoreSessionUTM"));
                    }

                    if (!GetFirstUTMData()) {
                        window.localStorage.setItem(
                            "FirstUTMTime",
                            new Date().toISOString() // use ISO formatting - everything else is ISO formatted.  This is a UTC date
                        );
                        window.localStorage.setItem("FirstUTM", trackingUTM);
                    }
                } else {
                    window.dispatchEvent(new Event("restoreSessionUTM"));
                }
            }
        }
    }, []);
    return <></>;
};
