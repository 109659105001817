import { dispatchRouteChangeEvent } from "./src/util/events";

/**
 * Gatsby runs this hook when the user navigates to a new route/page.
 * We dispatch our own event here that we can use in our React app.
 * Eg - send a "page view" analytics event on route change.
 *
 * @see [onRouteUpdate docs](https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onRouteUpdate)
 * @see [@reach/router and Gatsby](https://www.gatsbyjs.com/docs/reach-router-and-gatsby/)
 * @see [Reach Router docs](https://reach.tech/router/)
 *
 * @privateRemarks
 * Gatsby doesn't have a very nice API for hooking into routing events in a
 * React context. This is probably because they use Reach Router under the
 * hood, which itself doesn't make it easy. If they implement some easier
 * solution in the future (like a React hook), feel free to get rid of this.
 */
export const onRouteUpdate = ({ location, prevLocation }) => {
    dispatchRouteChangeEvent({ location, prevLocation });
};
