/* tslint:disable:object-literal-sort-keys */

import {
    TEAMRHYTHM_PATH,
    PROGRAMS_PATH,
    PERSONAS_PATH,
    ROADMAPS_PATH,
} from "../../constants/urls";

import personasIcon from "../../../content/imgs/products/PersonasIcon.svg";
import programsIcon from "../../../content/imgs/products/ProgramsIcon.svg";
import roadmapsIcon from "../../../content/imgs/products/RoadmapsIcon.svg";
import teamRhythmIcon from "../../../content/imgs/products/TeamRhythmIcon.svg";

export interface NavLinkType {
    heading: string;
    copy?: string;
    icon?: string;
    url: string;
    ctaLink?: string;
    ctaText?: string;
    border?: string;
}

interface NavListType {
    category: NavCategoryType;
    defaultOpenOnMobile?: boolean;
    links: NavLinkType[];
}

export type NavCategoryType = "jira apps" | "solutions";

export const navLists: NavListType[] = [
    {
        category: "jira apps",
        defaultOpenOnMobile: true,
        links: [
            {
                heading: `TeamRhythm`,
                url: TEAMRHYTHM_PATH,
                copy: "Better sprint planning and retrospectives with user story maps in Jira.",
                icon: teamRhythmIcon,
                ctaLink: TEAMRHYTHM_PATH,
                ctaText: "Features & Pricing",
                border: "transparent",
            },
            {
                heading: `Programs`,
                url: PROGRAMS_PATH,
                copy: "Manage complex dependencies with our SAFe® PI Planning suite in Jira.",
                icon: programsIcon,
                ctaLink: PROGRAMS_PATH,
                ctaText: "Features & Pricing",
                border: "transparent",
            },
            {
                heading: `Personas`,
                url: PERSONAS_PATH,
                copy: "Build customer empathy with your team in Jira.",
                icon: personasIcon,
                ctaLink: PERSONAS_PATH,
                ctaText: "Features & Pricing",
                border: "transparent",
            },
            {
                heading: `Roadmaps`,
                url: ROADMAPS_PATH,
                copy: "Plan the road ahead for your project in Jira.",
                icon: roadmapsIcon,
                ctaLink: ROADMAPS_PATH,
                ctaText: "Features & Pricing",
                border: "white",
            },
        ],
    },
    {
        category: "solutions",
        links: [
            {
                heading: `Case Studies`,
                url: `/customers/`,
            },
            {
                heading: `Find an Agile Partner`,
                url: `/partners/`,
            },
            {
                heading: `Join the Partner Program`,
                url: `/partners/program/`,
            },
            {
                heading: `Partner Resource Center`,
                url: `/partners/partner-resource-center/`,
            },
        ],
    },
];
