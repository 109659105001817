import React, { CSSProperties, useRef } from "react";
import styled from "styled-components";
import { Player } from "@lottiefiles/react-lottie-player";
import { useInView } from "react-intersection-observer";

const lottieContainerClass = "lf-player-container";
const LottiePlayerWrapper = styled.div`
    .${lottieContainerClass} {
        width: 100%;
        height: 100%;
    }
`;

const InnerPlayer = styled(Player)`
    width: 100%;
    height: 100%;
`;

interface LottieProps {
    lottieSrc: string;
    className?: string;
    style?: CSSProperties;
    autoplay?: boolean;
}

export const Lottie = ({
    lottieSrc,
    className,
    style,
    autoplay = false,
    ...props
}: LottieProps) => {
    // under the covers if you dont give an id to the player it calls every player lottie.
    // https://github.com/LottieFiles/lottie-react/blob/e8f34334b465e0aaf7588cea459fb1aeb507a0c1/src/Player.tsx#L236
    const lottieId = lottieSrc ? `lottie_${lottieSrc.length}` : "lottie";
    const playerRef = useRef();

    const wrapperProps = {
        className,
        style,
        ...props,
    };

    const { ref } = useInView({
        threshold: 0.1,
        triggerOnce: false,
        onChange: (inView) => {
            const lottiePlayer = playerRef?.current as Player;
            if (!inView) {
                lottiePlayer.pause();
            } else {
                lottiePlayer.play();
            }
        },
    });

    return (
        <LottiePlayerWrapper ref={ref} {...wrapperProps} data-hj-suppress>
            <InnerPlayer
                ref={playerRef}
                autoplay={autoplay}
                loop={true}
                src={lottieSrc}
                id={lottieId}
            />
        </LottiePlayerWrapper>
    );
};
