import { css } from "styled-components";
import { theme } from "./theme";

// TECH DEBT
// We are working to slowly delete everything from this file and replace with
// the new <Heading> and <Paragraph> system.

// https://seek-oss.github.io/capsize/

/** @deprecated Use `body`/`heading` styles, or `Heading`/`Paragraph` components */
export const bold20Type = css`
    font-weight: ${theme.fontWeight.heavy};
    font-style: ${theme.fontStyle.normal};
    font-size: ${theme.fontSize.headingMedium};
    line-height: 44px;
    padding: 0.05px 0;

    ::before {
        content: "";
        margin-top: -0.3896em;
        display: block;
        height: 0;
    }

    ::after {
        content: "";
        margin-bottom: -0.4636em;
        display: block;
        height: 0;
    }
`;

/** @deprecated Use `body`/`heading` styles, or `Heading`/`Paragraph` components */
export const bold14Type = css`
    font-weight: ${theme.fontWeight.heavy};
    font-style: ${theme.fontStyle.normal};
    font-size: ${theme.fontSize.regularLarge};
    line-height: 26px;
    padding: 0.05px 0;

    ::before {
        content: "";
        margin-top: -0.269em;
        display: block;
        height: 0;
    }

    ::after {
        content: "";
        margin-bottom: -0.343em;
        display: block;
        height: 0;
    }
`;

/** @deprecated Use `body`/`heading` styles, or `Heading`/`Paragraph` components */
export const bold12Type = css`
    font-weight: ${theme.fontWeight.heavy};
    font-style: ${theme.fontStyle.normal};
    font-size: ${theme.fontSize.regular};
    line-height: 20px;
    padding: 0.05px 0;

    ::before {
        content: "";
        margin-top: -0.2019em;
        display: block;
        height: 0;
    }

    ::after {
        content: "";
        margin-bottom: -0.2759em;
        display: block;
        height: 0;
    }
`;

/** @deprecated Use `body`/`heading` styles, or `Heading`/`Paragraph` components */
export const regular16Type = css`
    font-weight: ${theme.fontWeight.normal};
    font-style: ${theme.fontStyle.normal};
    font-size: ${theme.fontSize.headingSmall};
    line-height: 28px;
    padding: 0.05px 0;

    ::before {
        content: "";
        margin-top: -0.2307em;
        display: block;
        height: 0;
    }

    ::after {
        content: "";
        margin-bottom: -0.3047em;
        display: block;
        height: 0;
    }
`;

/** @deprecated Use `body`/`heading` styles, or `Heading`/`Paragraph` components */
export const regular14Type = css`
    font-weight: ${theme.fontWeight.normal};
    font-style: ${theme.fontStyle.normal};
    font-size: ${theme.fontSize.regularLarge};
    line-height: 26px;
    padding: 0.05px 0;

    ::before {
        content: "";
        margin-top: -0.269em;
        display: block;
        height: 0;
    }

    ::after {
        content: "";
        margin-bottom: -0.343em;
        display: block;
        height: 0;
    }
`;

/** @deprecated Use `body`/`heading` styles, or `Heading`/`Paragraph` components */
export const regular12Type = css`
    font-weight: ${theme.fontWeight.normal};
    font-style: ${theme.fontStyle.normal};
    font-size: ${theme.fontSize.regular};
    line-height: 24px;
    padding: 0.05px 0;

    ::before {
        content: "";
        margin-top: -0.3199em;
        display: block;
        height: 0;
    }

    ::after {
        content: "";
        margin-bottom: -0.3939em;
        display: block;
        height: 0;
    }
`;

/** @deprecated Use `bodyStyles`/`headingStyles` styles, or `Heading`/`Paragraph` components */
export const regular10Type = css`
    font-weight: ${theme.fontWeight.normal};
    font-style: ${theme.fontStyle.normal};
    font-size: ${theme.fontSize.regularSmall};
    line-height: 18px;
    padding: 0.05px 0;

    ::before {
        content: "";
        margin-top: -0.2497em;
        display: block;
        height: 0;
    }

    ::after {
        content: "";
        margin-bottom: -0.3237em;
        display: block;
        height: 0;
    }
`;
