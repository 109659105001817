import { ReactNode } from "react";

declare global {
    interface Window {
        AP: any;
        Arijea: any;
    }
}

export type FooterLinkType = {
    path?: string;
    component?: () => ReactNode;
    children?: string;
};

export enum IconType {
    close = "close",
    email = "email",
    externalLink = "externalLink",
    leftArrow = "leftArrow",
    linkedIn = "linkedIn",
    location = "location",
    menu = "menu",
    minus = "minus",
    phone = "phone",
    plus = "plus",
    rightArrow = "rightArrow",
    twitter = "twitter",
    youtube = "youtube",
}

export enum SocialMedia {
    linkedIn = "linkedIn",
    twitter = "twitter",
    youtube = "youtube",
}

export interface SocialMediaLink {
    type: SocialMedia;
    url: string;
}

/**
 * Describes a special route which may not have a nav or want the layout component
 */
export interface LayoutSettings {
    /** pattern match against pathname */
    appliesToPath?: RegExp;
    /** do you want the nav */
    hasNav: boolean;
    /** do you want the footer - could be deprecated */
    hasFocusedFooter: boolean;
    /** do you want the layout container at all */
    useLayout: boolean;
    /** do you want to use the footer at all */
    hasFooter: boolean;
}

export interface MarketplaceProduct {
    appId: string;
    distribution: {
        installationCount: number;
    };
    reviewSummary?: {
        rating: number;
        count?: number;
    };
}

export type MarketplaceProducts = Record<string, MarketplaceProduct>;

export interface HomeProductsSectionProps {
    marketplaceProducts: MarketplaceProducts;
}

export interface MarketplaceProductReview {
    rating: number;
    numReviews: number;
    numInstalls: number;
}
