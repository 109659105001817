import React, { useContext, useEffect, useMemo, useState } from "react";
import styled, { css, ThemeContext } from "styled-components";
import { IconType } from "../../types";
import { Icon } from "../Icon";
import { navLists, NavLinkType } from "./data";
import { leftFadeInAnimation } from "../Animations";
import { BLOG_PATH, GET_DEMO } from "../../constants/urls";
import {
    NavItemWrapper,
    SubNavWrapper,
    NavBar,
    CompanyLogo,
    TopLayoutContent,
    NavHeading,
    TextLink,
    NavHeadingText,
} from "./navComponents";

import { Logo } from "../icons/Logo";
import { gridSquares } from "../../styles/grid";

import { SearchBar } from "../Search/SearchBar";
import { useSearchUIMode } from "../Search/SearchUIModeProvider";
import { Arrow } from "../icons/Arrow";

const Menu = styled.div`
    min-width: 322px;
    display: flex;
    flex-direction: column;
`;

const MenuButton = styled.button`
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
`;

const MenuWrapper = styled.div<{ isOpen?: boolean }>`
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0px;
    background: ${(p) => p.theme.color.secondaryOne.main};
    ${(p) => {
        if (p.isOpen)
            return css`
                display: flex;
                align-items: center;
                justify-content: center;
                animation: ${leftFadeInAnimation} 0.35s ease-in;
            `;
        return "display: none;";
    }}
`;

const MobileSubNavWrapper = styled(SubNavWrapper)`
    min-width: 306px;

    a {
        padding: ${gridSquares(0.5)} ${gridSquares(1)};
    }
`;

interface NavItemType {
    heading: string;
    links?: NavLinkType[];
    link?: string;
    selectedCategory?: string;
    setSelectedCategory?: (category: string) => void;
    hideMenu: () => void;
}

const NavItem = ({
    heading,
    links,
    link,
    selectedCategory,
    setSelectedCategory,
    hideMenu,
}: NavItemType) => {
    const isOpen = useMemo(
        () => selectedCategory === heading,
        [selectedCategory, heading]
    );

    if (link)
        return (
            <NavItemWrapper>
                <NavHeading>
                    <TextLink url={link} onClick={hideMenu}>
                        <NavHeadingText>{heading}</NavHeadingText>
                    </TextLink>
                </NavHeading>
            </NavItemWrapper>
        );

    return (
        <NavItemWrapper>
            <NavHeading
                onClick={() => {
                    if (selectedCategory === heading) setSelectedCategory("");
                    else setSelectedCategory(heading);
                }}
                $clickable={true}
            >
                <NavHeadingText>{heading}</NavHeadingText>
                <Arrow
                    direction={isOpen ? "up" : "down"}
                    size="12px"
                    color="white"
                />
            </NavHeading>
            <MobileSubNavWrapper $isOpen={isOpen}>
                {links.map((textLink) => (
                    <TextLink
                        key={textLink.url}
                        url={textLink.url}
                        onClick={hideMenu}
                    >
                        <span>{textLink.heading}</span>
                    </TextLink>
                ))}
            </MobileSubNavWrapper>
        </NavItemWrapper>
    );
};

export const MobileNav = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSearchActive, setIsSearchActive] = useSearchUIMode();

    const [selectedCategory, setSelectedCategory] = useState("");

    const hideMenu = () => {
        setIsMenuOpen(false);
    };

    const theme = useContext(ThemeContext);

    const {
        cornerButtonType,
        cornerButtonColor,
        cornerButtonAction,
        searchButtonDisplay,
        logoDisplay,
        logoColor,
    } = useMemo(() => {
        if (isSearchActive) {
            return {
                cornerButtonColor: theme.color.primary.main,
                cornerButtonType: IconType.close,
                cornerButtonAction: () => setIsSearchActive(false),
                searchButtonDisplay: true,
                logoColor: theme.color.primary.main,
                logoDisplay: true,
            };
        }

        if (isMenuOpen) {
            return {
                cornerButtonColor: theme.color.primary.contrastText,
                cornerButtonType: IconType.close,
                cornerButtonAction: () => setIsMenuOpen(false),
                searchButtonDisplay: false,
                logoColor: theme.color.primary.main,
                logoDisplay: false,
            };
        }

        return {
            cornerButtonColor: theme.color.primary.contrastText,
            cornerButtonType: IconType.menu,
            cornerButtonAction: () => setIsMenuOpen(true),
            searchButtonDisplay: true,
            logoDisplay: true,
            logoColor: theme.color.primary.contrastText,
        };
    }, [isMenuOpen, isSearchActive, theme]);

    useEffect(() => {
        const defaultOpen = navLists.filter((list) => list.defaultOpenOnMobile);
        if (defaultOpen.length > 0)
            setSelectedCategory(defaultOpen[0].category);
    }, []);

    return (
        <NavBar $display="mobile">
            {/*
                Use data-nosnippet to prevent Google from using the nav as a description.  Can only be applied to divs, sections or span tags
                https://developers.google.com/search/docs/crawling-indexing/robots-meta-tag#data-nosnippet-attr
            */}
            <TopLayoutContent data-nosnippet>
                <CompanyLogo
                    aria-label="Easy Agile Home"
                    to="/"
                    $color={logoColor}
                >
                    {logoDisplay && <Logo />}
                </CompanyLogo>

                {searchButtonDisplay && (
                    <MenuButton
                        aria-label="Search"
                        className="nav-site-search-button nav-site-search-button-mobile"
                    >
                        <SearchBar />
                    </MenuButton>
                )}
                <MenuButton aria-label="Menu" onClick={cornerButtonAction}>
                    <Icon
                        type={cornerButtonType}
                        color={cornerButtonColor}
                        size="l"
                    />
                </MenuButton>
            </TopLayoutContent>
            <MenuWrapper data-nosnippet isOpen={isMenuOpen}>
                <Menu>
                    {navLists.map(
                        ({ category, links, defaultOpenOnMobile }) => (
                            <NavItem
                                key={category}
                                links={links}
                                heading={category}
                                selectedCategory={selectedCategory}
                                setSelectedCategory={setSelectedCategory}
                                hideMenu={hideMenu}
                            />
                        )
                    )}
                    <NavItem
                        hideMenu={hideMenu}
                        heading="blog"
                        link={BLOG_PATH}
                    />
                    <NavItem
                        hideMenu={() => {
                            hideMenu();
                        }}
                        data-conversionid="nav-get-demo"
                        link={GET_DEMO}
                        heading="Get a demo"
                    />
                </Menu>
            </MenuWrapper>
        </NavBar>
    );
};
