import React, { useState } from "react";
import styled from "styled-components";
import { ListHeader } from "./SearchHit";
import { Paragraph } from "../Typography/Paragraph";
import {
    useClearRefinements,
    useRefinementList,
} from "react-instantsearch-hooks-web";
import { MdCheckBox } from "@react-icons/all-files/md/MdCheckBox";
import { MdCheckBoxOutlineBlank } from "@react-icons/all-files/md/MdCheckBoxOutlineBlank";
import { theme } from "../../styles/theme";
import { Arrow } from "../icons/Arrow";

export const DesktopFilterSection = () => {
    return (
        <DesktopFilterContainer>
            <DesktopFilterHeader>
                <ListHeader as="h3">Filter by</ListHeader>
            </DesktopFilterHeader>
            <FilterList attribute={"filterCategory"} />
        </DesktopFilterContainer>
    );
};

export const MobileFilterSection = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <MobileFiltersMenu>
            <MobileFilterHeader
                onClick={() => {
                    setIsExpanded(!isExpanded);
                }}
            >
                Filter by
                <Arrow
                    direction={isExpanded ? "up" : "down"}
                    color={theme.color.secondaryOne.main}
                    size={"0.7em"}
                />
            </MobileFilterHeader>
            <MobileFilterList isExpanded={isExpanded}>
                <FilterList attribute={"filterCategory"} />
            </MobileFilterList>
        </MobileFiltersMenu>
    );
};

const FilterList = (props) => {
    const { items, refine } = useRefinementList(props);
    const { refine: clear } = useClearRefinements(props);

    return (
        <>
            <FilterListContainer>
                {items.map((item) => (
                    <Filter
                        filter={item}
                        key={`${item.value}`}
                        refine={refine}
                    />
                ))}
            </FilterListContainer>
            <FilterFooter>
                <ActionButton onClick={clear}>Clear</ActionButton>
            </FilterFooter>
        </>
    );
};

const Filter = ({ filter, refine }) => {
    const handleClick = () => {
        refine(filter.value);
    };

    return (
        <button onClick={handleClick}>
            <FilterItem>
                {filter.isRefined ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}

                <FilterText>{filter.label}</FilterText>
            </FilterItem>
        </button>
    );
};

const FilterListContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 10px;
`;

const FilterText = styled(Paragraph)`
    font-weight: ${(p) => p.theme.fontWeight.heavy};
    margin: 0px;
    color: black;
    text-align: left;
`;

const FilterItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px;
    padding-bottom: 8px;

    svg {
        color: ${(p) => p.theme.color.secondaryOne.main};
        min-width: 1.5em;
        min-height: 1.5em;
        margin-right: 10px;
    }
`;

const ActionButton = styled.button`
    text-transform: uppercase;
    color: ${(p) => p.theme.color.secondaryOne.main};
    font-size: 14px;
    line-height: 1em;
    font-weight: ${(p) => p.theme.fontWeight.bold};
    padding: 8px 0;
    text-align: center;

    cursor: pointer;

    :hover {
        color: ${(p) => p.theme.color.secondaryTwo.main};
    }
`;

const FilterFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 16px 0px;
`;

const DesktopFilterContainer = styled.div`
    display: none;

    ${(p) => p.theme.media("lg")} {
        display: block;
        width: 250px;
        padding: 16px 20px;
        background: white;
        box-shadow: ${(props) => props.theme.boxShadow.xs};
        bottom: unset;
        max-height: 80vh;
    }
`;

const DesktopFilterHeader = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 16px;
`;

const MobileFiltersMenu = styled.div`
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${(p) => p.theme.color.divider.subtle};
    border-bottom: 1px solid ${(p) => p.theme.color.divider.subtle};
    max-width: 90vw;
    margin: 16px 0px;

    ${(p) => p.theme.media("lg")} {
        display: none;
    }
`;

const MobileFilterHeader = styled.button`
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    color: ${(p) => p.theme.color.secondaryOne.main};
    font-size: 14px;
    line-height: 1em;
    font-weight: ${(p) => p.theme.fontWeight.bold};
    padding: 24px 0px;
    text-align: center;
    cursor: pointer;

    svg {
        margin-top: 1px;
        margin-left: 8px;
    }
`;

const MobileFilterList = styled.div<{ isExpanded }>`
    display: ${(p) => (p.isExpanded ? "block" : "none")};
`;
