import styled, { keyframes } from "styled-components";
import { gridSquares } from "../../styles/grid";

export const DownFadeIn = styled.div`
    @keyframes downFadeIn {
        0% {
            opacity: 0;
            transform: translateY(-${gridSquares(1.25)});
        }
        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }

    animation: downFadeIn 0.25s ease-in;
`;

export const downFadeInAnimation = keyframes`
    from {
        opacity: 0;
        transform: translateY(-${gridSquares(1.25)});
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
`;

export const leftFadeInAnimation = keyframes`
    from {
        opacity: 0;
        transform: translateX(${gridSquares(1.25)});
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
`;
