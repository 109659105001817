import { GatsbyDeployMode, getDeployEnvironment } from "../../buildEnvironment";

export const LANDING_PAGE_DESCRIPTION = `Agile made easy for everyone on your team.`;

// We have various situations in which a build happens. The aim is to have a
// constant representing each...
// - Production deployment: the Gatsby Cloud build that is deployed to production (www.easyagile.com)
// - CMS draft preview: the build used to preview Sanity drafts from the production CMS.
// - PR preview: preview builds that the Gatsby Cloud bot posts on GitHub pull requests.
// - Local dev build: The thing what happens when you run `yarn start` locally.

// Env vars that have boolean values are typed as strings until you
// cast them as booleans using this function.
export const checkBool = (envVar: string | boolean | undefined): boolean => {
    if (envVar === true) {
        return true;
    }

    if (typeof envVar === "string") {
        return envVar.toLowerCase() === "true";
    }

    return false;
};

// Some of these are built-in env vars from Gatsby Cloud, while others are set by us.
// https://www.gatsbyjs.com/docs/reference/cloud/gatsby-cloud-environment-variables/

// Build environments
export const isLocalDev = () =>
    getDeployEnvironment() === GatsbyDeployMode.LocalDev;

export const isCloudBuild = () =>
    getDeployEnvironment() !== GatsbyDeployMode.LocalDev;

// Deployment environments/targets

// The branch name is currently the only way we can differentiate a build
// running in Gatsby Cloud between production and PR preview builds.
export const isPrPreview = () =>
    getDeployEnvironment() === GatsbyDeployMode.PRPreview;

export const isCmsDraftPreview = () =>
    getDeployEnvironment() === GatsbyDeployMode.CMSPreview;
export const isProductionDeployment = () =>
    getDeployEnvironment() === GatsbyDeployMode.Production;

// Analytics config...
// Add to your .env if needed to test locally.
export const SEGMENT_WRITE_KEY = process.env.GATSBY_SEGMENT_WRITE_KEY;

// Tags set on blog posts in Sanity to determine if they should show in
// in-app notification feeds.
// TODO: In future, make shared constants between CMS and website codebases.
export const IN_APP_ONLY_TAG = "in-app-only";
export const IN_APP_TAG = "in-app";
export const HIGHLIGHT_ALL_TAG = "highlight_all";
export const NEWS_FEED_TAGS = [IN_APP_ONLY_TAG, IN_APP_TAG, HIGHLIGHT_ALL_TAG];

// Events...
// Our own custom event. __ suffix to avoid potential name clashes with other libs
export const ROUTE_CHANGE_EVENT_NAME = "RouteChangeEvent__";

export const PRODUCTION_DOMAINS = ["easyagile.com", "www.easyagile.com"];

export const HUBSPOT_ACCOUNT_ID = 40697267;
