import { gridSquares } from "../styles/grid";
import React from "react";
import styled, { css } from "styled-components";
import { IconType } from "../types";

const SIZE_MAP = {
    l: gridSquares(1.5),
    m: gridSquares(1.25),
    s: gridSquares(1),
    xs: gridSquares(0.75),
};

interface SvgProps {
    size?: "l" | "m" | "s" | "xs";
    isMarginLeft?: boolean;
    isMarginRight?: boolean;
    color?: string;
}

const Svg = styled.svg<SvgProps>`
    vertical-align: middle;

    width: ${(p) => SIZE_MAP[p.size || "m"]};
    height: ${(p) => SIZE_MAP[p.size || "m"]};

    fill: ${(p) => p.color || p.theme.color.background.contrastText};

    ${(p) =>
        p.isMarginLeft &&
        css`
            margin-left: ${gridSquares(0.5)};
        `}

    ${(p) =>
        p.isMarginRight &&
        css`
            margin-right: ${gridSquares(0.5)};
        `}
`;

const shapes = {
    [IconType.close]: (
        <path d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" />
    ),
    [IconType.externalLink]: (
        <>
            <polygon points="15.93 0 15.93 2.31 20.07 2.31 8.73 13.65 10.35 15.27 21.69 3.93 21.69 8.07 24 8.07 24 0 15.93 0" />
            <path d="M19.39,21.58h-17v-17h8.49V2.18H2.42A2.42,2.42,0,0,0,0,4.61v17A2.42,2.42,0,0,0,2.42,24h17a2.43,2.43,0,0,0,2.43-2.42V13.09H19.39Z" />
        </>
    ),
    [IconType.leftArrow]: (
        <path d="M19.41,2.82,10.25,12l9.16,9.18L16.59,24l-12-12,12-12Z" />
    ),
    [IconType.linkedIn]: (
        <path d="M19.67,3H4.33A1.31,1.31,0,0,0,3,4.29V19.71A1.31,1.31,0,0,0,4.33,21H19.67A1.31,1.31,0,0,0,21,19.71V4.29A1.31,1.31,0,0,0,19.67,3ZM8.46,18.07H5.74V9.94H8.46ZM7.1,8.83h0A1.41,1.41,0,1,1,7.11,6a1.41,1.41,0,1,1,0,2.81Zm11.16,9.24H15.54V13.72c0-1.09-.39-1.84-1.38-1.84a1.48,1.48,0,0,0-1.39,1,1.88,1.88,0,0,0-.09.66v4.54H10s0-7.37,0-8.13h2.72v1.15a2.71,2.71,0,0,1,2.45-1.34c1.79,0,3.13,1.16,3.13,3.66Z" />
    ),
    [IconType.location]: (
        <path d="M12,11.4a3,3,0,1,1,3-3,3,3,0,0,1-3,3M12,0A8.4,8.4,0,0,0,3.6,8.4C3.6,14.7,12,24,12,24s8.4-9.3,8.4-15.6A8.4,8.4,0,0,0,12,0Z" />
    ),
    [IconType.menu]: <path d="M3,18H21V16H3Zm0-5H21V11H3ZM3,6V8H21V6Z" />,
    [IconType.rightArrow]: (
        <path d="M4.59,21.18,13.75,12,4.59,2.82,7.41,0l12,12-12,12Z" />
    ),
    [IconType.twitter]: (
        <path d="M22.48,5.5A8.86,8.86,0,0,1,20,6.18,4.34,4.34,0,0,0,21.9,3.8a8.59,8.59,0,0,1-2.73,1,4.3,4.3,0,0,0-7.44,2.94,4.46,4.46,0,0,0,.11,1A12.21,12.21,0,0,1,3,4.27,4.25,4.25,0,0,0,2.4,6.43,4.31,4.31,0,0,0,4.31,10a4.15,4.15,0,0,1-1.95-.54v.06a4.29,4.29,0,0,0,3.45,4.21,4.23,4.23,0,0,1-1.13.16,4.28,4.28,0,0,1-.81-.08,4.29,4.29,0,0,0,4,3,8.63,8.63,0,0,1-5.35,1.85,8.72,8.72,0,0,1-1-.06,12.15,12.15,0,0,0,6.59,1.93A12.16,12.16,0,0,0,20.35,8.28c0-.19,0-.37,0-.56A8.69,8.69,0,0,0,22.48,5.5Z" />
    ),
    [IconType.phone]: (
        <path d="M4.83,10.39a20.19,20.19,0,0,0,8.78,8.78l2.94-2.93a1.32,1.32,0,0,1,1.36-.32,15.26,15.26,0,0,0,4.76.76A1.34,1.34,0,0,1,24,18v4.66A1.34,1.34,0,0,1,22.67,24,22.67,22.67,0,0,1,0,1.33,1.34,1.34,0,0,1,1.33,0H6A1.34,1.34,0,0,1,7.33,1.33a15.21,15.21,0,0,0,.76,4.76,1.33,1.33,0,0,1-.33,1.36Z" />
    ),
    [IconType.email]: (
        <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
    ),
    [IconType.youtube]: (
        <path d="M23.5,6.22A3,3,0,0,0,21.38,4.1C19.51,3.6,12,3.6,12,3.6s-7.51,0-9.38.5A3,3,0,0,0,.5,6.22,31.34,31.34,0,0,0,0,12a31.34,31.34,0,0,0,.5,5.78A3,3,0,0,0,2.62,19.9c1.87.5,9.38.5,9.38.5s7.51,0,9.38-.5a3,3,0,0,0,2.12-2.12A31.34,31.34,0,0,0,24,12,31.34,31.34,0,0,0,23.5,6.22ZM9.6,15.6V8.4L15.84,12Z" />
    ),
    [IconType.plus]: <path d="M20,13V11H13V4H11v7H4v2h7v7h2V13Z" />,
    [IconType.minus]: <rect x="3" y="10.5" width="18" height="3" />,
};

type ParentProps = SvgProps & {
    type: IconType;
};

export const Icon: React.FC<ParentProps> = ({ type, ...remainingProps }) => (
    <Svg viewBox="0 0 24 24" {...remainingProps}>
        <title>{type}</title>
        {shapes[type]}
    </Svg>
);
