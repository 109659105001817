import React from "react";
import styled, { css } from "styled-components";
import { ViewportSize } from "../../styles/theme";

type MarginProps = {
    noTopMargin: boolean;
    noBottomMargin: boolean;
};

const generateParagraphStyles = (
    noTopMargin: boolean,
    noBottomMargin: boolean,
    viewportSize?: ViewportSize
) => {
    const size = viewportSize || "xs"; // xs is default, no media query necessary

    return css`
        ${(p) => {
            const vals = p.theme.viewport[size].typography.body;

            const styleRules = `
                font-size: ${vals.fontSize};
                font-weight: ${vals.fontWeight};
                line-height: ${vals.lineHeight};
                letter-spacing: ${vals.letterSpacing};
                margin-top: ${noTopMargin ? "0px" : vals.marginTop};
                margin-bottom: ${noBottomMargin ? "0px" : vals.marginBottom};

                :first-child {
                    margin-top: 0;
                }

                :last-child {
                    margin-bottom: 0;
                }
            `;

            if (size === "xs") {
                return styleRules;
            }

            return `
                ${p.theme.media(size)} {
                    ${styleRules}
                }
            `;
        }};
    `;
};

/**
 * A generic paragraph component. Renders a styled `<p>` tag.
 */
const StyledParagraph = styled.p<MarginProps>`
    /*
        TODO: Ideally use components for these rather than styles.
        Eg - <Strong>
        Only here as a quick fix while porting from old paragraph styles.
    */
    strong {
        font-weight: ${(p) => p.theme.fontWeight.heavy};
    }

    a {
        text-decoration: underline;
    }

    i,
    em {
        font-style: ${(p) => p.theme.fontStyle.italic};
    }

    ${(p) => generateParagraphStyles(p.noTopMargin, p.noBottomMargin)};
    ${(p) => generateParagraphStyles(p.noTopMargin, p.noBottomMargin, "sm")};
    ${(p) => generateParagraphStyles(p.noTopMargin, p.noBottomMargin, "md")};
    ${(p) => generateParagraphStyles(p.noTopMargin, p.noBottomMargin, "lg")};
    ${(p) => generateParagraphStyles(p.noTopMargin, p.noBottomMargin, "xl")};
    ${(p) => generateParagraphStyles(p.noTopMargin, p.noBottomMargin, "xxl")};
    ${(p) => generateParagraphStyles(p.noTopMargin, p.noBottomMargin, "xxxl")};
`;

type ParagraphProps = React.ComponentProps<typeof StyledParagraph> & {
    noMargin?: boolean;
};

export const Paragraph: React.FC<ParagraphProps> = ({
    noMargin = false,
    noTopMargin = false,
    noBottomMargin = false,
    ...props
}) => {
    return (
        <StyledParagraph
            noTopMargin={noTopMargin || noMargin}
            noBottomMargin={noBottomMargin || noMargin}
            {...props}
        />
    );
};
