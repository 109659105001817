import styled, { css } from "styled-components";
import { ViewportSize } from "../../styles/theme";

export const generateDisclaimerStyles = (viewportSize?: ViewportSize) => {
    const size = viewportSize || "xs"; // xs is default, no media query necessary

    return css`
        ${(p) => {
            const vals = p.theme.viewport[size].typography.disclaimer;

            const styleRules = `
                font-size: ${vals.fontSize};
                font-weight: ${vals.fontWeight};
                line-height: ${vals.lineHeight};
                letter-spacing: ${vals.letterSpacing};
                margin-top: ${vals.marginTop};
                margin-bottom: ${vals.marginBottom};

                :first-child {
                    margin-top: 0;
                }

                :last-child {
                    margin-bottom: 0;
                }
            `;

            if (size === "xs") {
                return styleRules;
            }

            return `
                ${p.theme.media(size)} {
                    ${styleRules}
                }
            `;
        }};
    `;
};

export const disclaimerStyles = css`
    /*
        TODO: Ideally use components for these rather than styles.
        Eg - <Strong>
        Only here as a quick fix while porting from old paragraph styles.
    */
    strong {
        font-weight: ${(p) => p.theme.fontWeight.heavy};
    }

    a {
        text-decoration: underline;
    }

    i,
    em {
        font-style: ${(p) => p.theme.fontStyle.italic};
    }

    ${() => generateDisclaimerStyles()};
    ${() => generateDisclaimerStyles("sm")};
    ${() => generateDisclaimerStyles("md")};
    ${() => generateDisclaimerStyles("lg")};
    ${() => generateDisclaimerStyles("xl")};
    ${() => generateDisclaimerStyles("xxl")};
    ${() => generateDisclaimerStyles("xxxl")};
`;

export const Disclaimer = styled.p`
    ${disclaimerStyles}
`;
