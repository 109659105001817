import React, { useState } from "react";
import styled from "styled-components";
import { Hyperlink } from "../Link/Hyperlink";

import { Paragraph } from "../Typography/Paragraph";
import DefaultSearchImage from "./DefaultSearchImage";

import { useHits } from "react-instantsearch-hooks-web";
const HitImage = styled.div`
    svg {
        height: 40px;
        width: 40px;
        object-fit: cover;
        object-position: 0 50%;
        border-radius: ${(p) => p.theme.borderRadius.s};
    }

    img {
        height: 40px;
        width: 40px;
        object-fit: cover;
        object-position: 0 50%;
        border-radius: ${(p) => p.theme.borderRadius.s};
    }
`;

const HitItemText = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 20px;
`;

const HitTitle = styled(Paragraph)`
    font-weight: ${(p) => p.theme.fontWeight.bold};
`;

const HitWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 5px 0;
    align-items: center;

    color: ${(p) => p.theme.color.temp.utilityBlack};

    :hover {
        background: ${(p) => p.theme.color.background.dim};
    }
`;

export const HitsList = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 10px;

    ol li {
        list-style-type: none;
    }
`;

export const ListHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;
`;

export const ListHeader = styled(Paragraph)`
    text-transform: uppercase;
    margin-bottom: 0px;
`;

export const ResultsPill = styled.div`
    margin-left: 6px;
    border-radius: ${(p) => p.theme.borderRadius.l};
    padding-right: 6px;
    padding-left: 6px;
    background-color: ${(p) => p.theme.color.background.dim};
    color: ${(p) => p.theme.color.primary.main};
    font-weight: ${(p) => p.theme.fontWeight.bold};
    font-size: 11px;
`;

export const PaginationButton = styled.button`
    text-transform: uppercase;
    color: ${(p) => p.theme.color.secondaryOne.main};
    font-size: 14px;
    line-height: 1em;
    font-weight: ${(p) => p.theme.fontWeight.bold};
    padding: 8px 0;
    text-align: center;

    cursor: pointer;

    :hover {
        color: ${(p) => p.theme.color.secondaryTwo.main};
    }
`;

// this is a temporary solution; used a hyperlink text with PaginationButton styling
export const SeeMore = styled.p`
    text-transform: uppercase;
    color: ${(p) => p.theme.color.secondaryOne.main};
    font-size: 14px;
    line-height: 1em;
    font-weight: ${(p) => p.theme.fontWeight.bold};
    cursor: pointer;
    padding: 8px 0;
    text-align: center;

    :hover {
        color: ${(p) => p.theme.color.secondaryTwo.main};
    }
`;
//

export const SearchHit = ({ hit /*: AlgoliaHit*/, onClick }) => {
    return (
        <Hyperlink className="hitResult" href={`${hit.slug}`} onClick={onClick}>
            <HitWrapper>
                <HitImage>
                    {hit.media ? (
                        <img src={hit.media} />
                    ) : (
                        <DefaultSearchImage />
                    )}
                </HitImage>
                <HitItemText>
                    <HitTitle>{hit.title}</HitTitle>
                </HitItemText>
            </HitWrapper>
        </Hyperlink>
    );
};

// processes the full list of returned hits into categories
export const SearchHitsList = ({ category, hits }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { sendEvent } = useHits();

    const handleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const filteredHits = hits.filter(
        (hit) => hit.searchResultCategory === `${category}`
    );

    if (filteredHits.length === 0) {
        return null;
    }

    const hitsTitle = filteredHits[0].searchResultCategory;
    const cuttoff: number = 3;
    const displayedHits = isExpanded ? filteredHits : filteredHits.slice(0, 3);

    return (
        <HitsList>
            <ListHeaderWrapper>
                <ListHeader as="h3">{hitsTitle}</ListHeader>
                <ResultsPill>{filteredHits.length}</ResultsPill>
            </ListHeaderWrapper>
            {displayedHits.map((hit, index) => (
                <SearchHit
                    hit={hit}
                    onClick={() => {
                        // send an event back to the AlgoliaAnalyticsMiddleware
                        sendEvent(
                            "click",
                            { ...hit, position: index + 1 },
                            "Search Result"
                        );
                    }}
                    key={`hit${index}`}
                ></SearchHit>
            ))}
            {filteredHits.length > cuttoff && (
                <PaginationButton onClick={handleExpand}>
                    {!isExpanded ? "See more" : "See less"}
                </PaginationButton>
            )}
        </HitsList>
    );
};
