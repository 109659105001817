import React, { useEffect } from "react";
import styled from "styled-components";
import sanitizeHtml from "sanitize-html";
import { HUBSPOT_ACCOUNT_ID } from "../constants";
import { formStyles } from "./Form/Form";
import { inputStyles } from "./Form/Input";
import { buttonStyles } from "../styles/button";
import { generateHeadingStyles } from "./Typography/Heading";
import { generateDisclaimerStyles } from "./Typography/Disclaimer";
import { SimpleDotLoader } from "./Form/SimpleDotLoader";

const sanitizeForm = (
    form: HTMLFormElement,
    submissionValues: { name: string; value: unknown }[]
) => {
    for (let i = 0; i < form.length; i++) {
        const element = form[i] as HTMLInputElement | HTMLSelectElement;

        // The type in submissionValues better represents checkboxes, so we'll use that
        const submissionValue = submissionValues.find(
            (val) => val.name === element.name
        );

        // This is the submit button, leave it alone.
        if (element.type === "submit") {
            continue;
        }

        // A Hubspot hidden field containing information about the form submission. Do not touch.
        if (element.name === "hs_context") {
            continue;
        }

        if (typeof submissionValue.value === "string") {
            element.value = sanitizeHtml(element.value, {
                allowedTags: [],
                allowedAttributes: {},
            });
        }
    }
};

const defaultProperties = {
    region: "na1",
    portalId: HUBSPOT_ACCOUNT_ID,
    css: "", // Passing an emptry string here forces Hubspot to render the form on the page. This lets us style things.
    onBeforeFormSubmit: sanitizeForm,
};

// The container that will hold the Hubspot form
const HubspotFormContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    // Apply our common form styles to the components of this form.
    form {
        ${formStyles}

        input[type=text] {
            ${inputStyles}
        }

        input[type="email"] {
            ${inputStyles}
        }

        select {
            ${inputStyles}

            // Get rid of the default dropdown arrow
            -moz-appearance:none;
            -webkit-appearance: none;
            appearance: none;

            :hover {
                cursor: pointer;
            }
        }

        // Add a custom down arrow to the select by making a black box and clipping it
        .hs-fieldtype-select > div::after {
            content: "";
            background: black;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 48px;
            right: 0px;
            clip-path: path(
                "M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
            );
        }

        .hs-form-booleancheckbox-display {
            display: flex;
        }

        [type="submit"] {
            ${buttonStyles}

            cursor: pointer;
        }

        // Allows input elements to take up the entire container
        .hs-form-field {
            width: 100%;

            position: relative;
        }

        [role="alert"] {
            background-color: ${(p) => p.theme.color.error.main};

            position: absolute;
            top: -4px;
            right: 0px;

            border-radius: 4px;
            border: 1px solid ${(p) => p.theme.color.error.contrastText};
            padding: 4px;

            .hs-error-msg {
                color: ${(p) => p.theme.color.error.contrastText};
            }
        }

        h1 {
            ${generateHeadingStyles("h1", true, true, "lg")}
        }

        h2 {
            ${generateHeadingStyles("h2", true, true, "lg")}
        }

        h3 {
            ${generateHeadingStyles("h3", true, true, "lg")}
        }

        h4 {
            ${generateHeadingStyles("h4", true, true, "lg")}
        }

        h5 {
            ${generateHeadingStyles("h5", true, true, "lg")}
        }

        h6 {
            ${generateHeadingStyles("h6", true, true, "lg")}
        }

        .legal-consent-container {
            ${generateDisclaimerStyles("lg")}
        }

        a {
            text-decoration: underline;
        }
    }
`;

interface FormProps {
    formId: string;
    onSuccess?: (redirectUrl: string, data: Record<string, unknown>) => void;
}

/**
 * The Hubspot form component, used to add form(s) on a page.
 * @param formId The ID of the form to render
 * @returns hubspot form container
 */
export const HubspotForm = ({ formId, onSuccess }: FormProps) => {
    const targetElement = `#hubspotform_${formId}`;

    useEffect(() => {
        if (targetElement) {
            // Add the script element in here so we can add the event listener
            const script = document.createElement("script");
            script.src = "https://js.hsforms.net/forms/embed/v2.js";
            document.body.appendChild(script);

            script.addEventListener("load", () => {
                // @ts-ignore
                if (window.hbspt) {
                    // @ts-ignore
                    window.hbspt.forms.create({
                        target: targetElement,
                        formId,
                        onFormSubmitted: onSuccess,
                        ...defaultProperties,
                    });
                }
            });
        }
    }, [targetElement]);

    return (
        <HubspotFormContainer id={targetElement.substring(1)}>
            <SimpleDotLoader />
        </HubspotFormContainer>
    );
};
