import React from "react";
import styled, { css } from "styled-components";
import { FooterLinkType, SocialMedia, SocialMediaLink } from "../../types";
import { PageSectionContainer } from "../PageSection";
import { OutboundLink } from "../Link";
import {
    BLOG_PATH,
    CAREERS_PATH,
    COMPANY_PATH,
    CONTACT_EMAIL,
    CONTACT_US_URL,
    CUSTOMER_CASE_STUDIES_URL,
    DOCS_URL,
    EA_FACEBOOK_URL,
    EA_INSTAGRAM_URL,
    EA_LINKED_IN_URL,
    EA_TWITTER_URL,
    EA_YOUTUBE_URL,
    EVENTS_URL,
    GLOSSARY_URL,
    IN_THE_PRESS_PATH,
    LEARNING_HUB_URL,
    LICENSE_AGREEMENT_URL,
    MIGRATION_HUB_URL,
    PARTNER_RESOURCE_CENTER_URL,
    PERSONAS_PATH,
    PLEDGE_PATH,
    PODCAST_PATH,
    PROGRAMS_PATH,
    PUBLIC_ROADMAP,
    REPORTS_PATH,
    ROADMAPS_PATH,
    SUPPORT_URL,
    TEAMRHYTHM_PATH,
    TRUST_CENTER_URL,
} from "../../constants/urls";

import { RiFacebookFill } from "@react-icons/all-files/ri/RiFacebookFill";
import { RiInstagramLine } from "@react-icons/all-files/ri/RiInstagramLine";
import { RiMailLine } from "@react-icons/all-files/ri/RiMailLine";
import { XIcon } from "../icons/XIcon";
import { RiYoutubeLine } from "@react-icons/all-files/ri/RiYoutubeLine";

import { Link } from "gatsby";
import isInternalLink from "../../util/isInternalLink";
import { Logo } from "../icons/Logo";

interface LinkCategoryType {
    heading: string;
    links: FooterLinkType[];
}

export const websiteLinks: LinkCategoryType[] = [
    {
        heading: "Products",
        links: [
            {
                path: TEAMRHYTHM_PATH,
                children: "TeamRhythm",
            },
            {
                path: PROGRAMS_PATH,
                children: "Programs",
            },
            {
                path: PERSONAS_PATH,
                children: "Personas",
            },
            {
                path: ROADMAPS_PATH,
                children: "Roadmaps",
            },
        ],
    },
    {
        heading: "Resources",
        links: [
            {
                path: DOCS_URL,
                children: "Documentation",
            },
            {
                path: LICENSE_AGREEMENT_URL,
                children: "License Agreement",
            },
            {
                path: TRUST_CENTER_URL,
                children: "Trust Center & Privacy",
            },
            {
                path: SUPPORT_URL,
                children: "Support",
            },
            {
                path: MIGRATION_HUB_URL,
                children: "Migration Hub",
            },
            {
                path: PARTNER_RESOURCE_CENTER_URL,
                children: "Partner Resource Center",
            },
            {
                path: CUSTOMER_CASE_STUDIES_URL,
                children: "Customer Case Studies",
            },
            {
                path: LEARNING_HUB_URL,
                children: "Learning Hub",
            },
            {
                path: GLOSSARY_URL,
                children: "Agile Glossary",
            },
            {
                path: PUBLIC_ROADMAP,
                children: "Product Roadmap",
            },
            {
                component: () => (
                    <>
                        <FooterButton
                            className="cky-banner-element"
                            style={{
                                display: "var(--consent-mode-gdpr, block)",
                            }}
                        >
                            Cookie Settings
                        </FooterButton>
                        <FooterButton
                            className="cky-banner-element"
                            style={{
                                display:
                                    "var(--consent-mode-do-not-sell, none)",
                            }}
                        >
                            Cookie Settings / Do not sell my data
                        </FooterButton>
                    </>
                ),
            },
        ],
    },
    {
        heading: "Company",
        links: [
            {
                path: COMPANY_PATH,
                children: "About",
            },
            {
                path: CONTACT_US_URL,
                children: "Contact",
            },
            {
                path: BLOG_PATH,
                children: "Blog",
            },
            {
                path: CAREERS_PATH,
                children: "Careers",
            },
            {
                path: EVENTS_URL,
                children: "Events",
            },
            {
                path: PODCAST_PATH,
                children: "Podcast",
            },
            {
                path: IN_THE_PRESS_PATH,
                children: "In The Press",
            },
            {
                path: PLEDGE_PATH,
                children: "Pledge 1%",
            },
            {
                path: REPORTS_PATH,
                children: "Annual Reports",
            },
        ],
    },
];

export const socialMediaLinks: SocialMediaLink[] = [
    {
        type: SocialMedia.linkedIn,
        url: EA_LINKED_IN_URL,
    },
    {
        type: SocialMedia.youtube,
        url: EA_YOUTUBE_URL,
    },
    {
        type: SocialMedia.twitter,
        url: EA_TWITTER_URL,
    },
];

const StyledFooter = styled.footer`
    width: 100%;
    justify-content: center;
    padding-top: 60px;
    padding-bottom: 80px;
    background-color: ${(p) => p.theme.color.primary.main};
    color: ${(p) => p.theme.color.primary.contrastText};
`;

const FooterWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-gap: ${(p) => p.theme.grid.columnGap}px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;

    @media (min-width: ${(p) => p.theme.breakpoint.lg}px) {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
    }
`;

const CategoryList = styled.ul`
    display: grid;
    width: 100%;
    grid-template-columns: auto;
    justify-content: start;
    gap: 40px;

    @media (min-width: 500px) {
        grid-template-columns: 1fr 1fr;
        justify-content: start;
    }

    @media (min-width: ${(p) => p.theme.breakpoint.sm}px) {
        grid-template-columns: repeat(3, auto);
        gap: 40px;
    }

    @media (min-width: ${(p) => p.theme.breakpoint.md}px) {
        gap: 80px;
    }

    @media (min-width: ${(p) => p.theme.breakpoint.lg}px) {
        justify-content: space-evenly;
        gap: 40px;
    }

    @media (min-width: ${(p) => p.theme.breakpoint.xl}px) {
        gap: 80px;
    }
`;

const ContactCategory = styled.div`
    display: grid;
    grid-template-columns: auto;
    align-content: start;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 32px;

    @media (min-width: ${(p) => p.theme.breakpoint.sm}px) {
        grid-template-columns: auto auto;
    }

    @media (min-width: ${(p) => p.theme.breakpoint.lg}px) {
        grid-template-rows: 1fr;
        grid-template-columns: auto;
    }
`;

const iconSize = 24;

const SocialLinkButton = styled(OutboundLink)`
    display: inline-block;
    padding: 8px;
    vertical-align: middle;

    > svg {
        vertical-align: middle;
    }

    :hover {
        color: ${(p) => p.theme.color.accentOne.main};
    }
`;

const SocialMediaLinkList = styled.ul`
    margin-left: -10px;

    li {
        display: inline-block;
    }
`;

const FooterSocialMediaLinks = () => {
    return (
        <SocialMediaLinkList>
            <li>
                <SocialLinkButton
                    aria-label="X (formerly Twitter)"
                    href={EA_TWITTER_URL}
                >
                    <XIcon size={iconSize} />
                </SocialLinkButton>
            </li>
            <li>
                <SocialLinkButton aria-label="Facebook" href={EA_FACEBOOK_URL}>
                    <RiFacebookFill size={iconSize} />
                </SocialLinkButton>
            </li>
            <li>
                <SocialLinkButton
                    aria-label="Instagram"
                    href={EA_INSTAGRAM_URL}
                >
                    <RiInstagramLine size={iconSize} />
                </SocialLinkButton>
            </li>
            <li>
                <SocialLinkButton aria-label="Youtube" href={EA_YOUTUBE_URL}>
                    <RiYoutubeLine size={iconSize} />
                </SocialLinkButton>
            </li>
            <li>
                <SocialLinkButton
                    aria-label="Email"
                    href={`mailto:${CONTACT_EMAIL}`}
                    as="a"
                >
                    <RiMailLine size={iconSize} />
                </SocialLinkButton>
            </li>
        </SocialMediaLinkList>
    );
};

const CompanyLogo = styled.div`
    height: 38px;
    color: ${(p) => p.theme.color.primary.contrastText};

    svg {
        height: 100%;
    }
`;

const Contact = () => (
    <ContactCategory>
        <CompanyLogo>
            <Logo />
        </CompanyLogo>
        <FooterSocialMediaLinks />
    </ContactCategory>
);

const CopyrightText = styled.p`
    margin-top: 40px;

    @media (min-width: ${(p) => p.theme.breakpoint.lg}px) {
        margin-top: 0;
    }
`;

const Category = styled.li``;

const CategoryHeading = styled.h3`
    font-size: 18px;
    font-weight: ${(p) => p.theme.fontWeight.bold};
`;

const LinksUl = styled.ul`
    display: flex;
    flex-direction: column;

    li {
        margin-top: 0.5em;
    }
`;

const linkStyle = (p) => css`
    font-size: 18px;
    width: 100%;
    display: inline-block;
    white-space: nowrap;

    :hover {
        text-decoration: underline;
    }
`;

const InternalLink = styled(Link)`
    ${linkStyle}
`;

const ExternalLink = styled(OutboundLink)`
    ${linkStyle}
`;

const FooterButton = styled.span`
    cursor: pointer;
    ${linkStyle}
`;

const WebsiteLinks = () => (
    <>
        {websiteLinks.map(({ heading, links }) => (
            <Category key={heading}>
                <CategoryHeading>{heading}</CategoryHeading>
                <LinksUl>
                    {links.map(({ path, component, children }) => {
                        if (component) {
                            return <li key={children}>{component()}</li>;
                        } else if (path !== undefined) {
                            return (
                                <li key={path}>
                                    {isInternalLink(path) ? (
                                        <InternalLink key={path} to={path}>
                                            {children}
                                        </InternalLink>
                                    ) : (
                                        <ExternalLink key={path} href={path}>
                                            {children}
                                        </ExternalLink>
                                    )}
                                </li>
                            );
                        }
                    })}
                </LinksUl>
            </Category>
        ))}
    </>
);

export const Footer = ({ isFocusedFooter }) => (
    <StyledFooter id="ea-footer">
        <PageSectionContainer>
            <FooterWrapper>
                <Contact />
                {!isFocusedFooter && (
                    <CategoryList>
                        <WebsiteLinks />
                    </CategoryList>
                )}
            </FooterWrapper>

            <CopyrightText>Copyright 2024, Easy Agile.</CopyrightText>
        </PageSectionContainer>
    </StyledFooter>
);
