import React from "react";
import styled from "styled-components";
import { Lottie } from "./Lottie";
import { GatsbyImage } from "gatsby-plugin-image";

const PromoStripLottie = styled(Lottie)`
    height: 100%;
`;

/**
 * A component that accepts an object containing fields matching the `graphic` Sanity object to allow for Lottie animation OR static image rendering.
 *
 * Use where both lottie and static images will be used
 *
 * @param imageData - Object containing `imageType`, `gatsbyImageData` and `lottieFile` fields from a graphql query.
 *
 * @returns
 * Image component based on `imageType` field
 * ```tsx
 * <RenderImage imageData={graphql_result} />
 * ```
 */
export const RenderImage = ({ imageData, loading }) => {
    // If no image type specified, return null
    if (!imageData?.imageType) return null;

    if (imageData.imageType === "lottie") {
        if (!imageData?.lottieFile?.asset?.url) return null;

        return <PromoStripLottie lottieSrc={imageData.lottieFile.asset.url} />;
    }

    const gatsbyImageData = imageData?.image?.asset?.gatsbyImageData;

    if (!gatsbyImageData) return null;

    return (
        <GatsbyImage
            alt=""
            loading={loading || "lazy"}
            image={gatsbyImageData}
            style={{ width: "100%", height: "100%" }}
        />
    );
};
