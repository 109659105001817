import React from "react";
import styled from "styled-components";

import { MdSearch } from "@react-icons/all-files/md/MdSearch";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import Button from "../Button";
import { useSearchUIMode } from "./SearchUIModeProvider";

/**
 * this is the button that appears in the nav, but its also the close
 * button for the SearchResultsDropDown in mobile view.
 */
export const SearchBarToggleButton = () => {
    const [isSearchActive, setIsSearchActive] = useSearchUIMode();

    return (
        <SearchButton
            aria-label="Search"
            role="button"
            isSearchActive={isSearchActive}
            onClick={() => {
                setIsSearchActive(!isSearchActive);
            }}
        >
            <IconWrapper>
                {isSearchActive ? <MdClose /> : <MdSearch />}
            </IconWrapper>
        </SearchButton>
    );
};

const SearchButton = styled.div<{ isSearchActive: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: calc(${(p) => p.theme.height.navBar} - 32px);
    margin: 0px;
    padding: 12px;
    border: 0px;
    background-color: ${(p) =>
        p.isSearchActive ? p.theme.color.primary.contrastText : "unset"};
    cursor: pointer;

    /* search icon - desktop nav */
    ${(p) => p.theme.media("lg")} {
        padding-left: 12px;
        background-color: ${(p) =>
            p.isSearchActive ? p.theme.color.secondaryOne.main : "unset"};

        color: ${(p) =>
            p.isSearchActive
                ? p.theme.color.secondaryOne.contrastText
                : p.theme.color.primary.contrastText};

        :hover {
            background-color: ${(p) =>
                p.isSearchActive ? p.theme.color.secondaryOne.main : "unset"};
            color: ${(p) =>
                p.isSearchActive
                    ? "red"
                    : p.theme.color.secondaryOne.contrastText};
        }
    }

    svg {
        /* search icon - mobile nav */
        fill: ${(p) =>
            p.isSearchActive
                ? p.theme.color.primary.main
                : p.theme.color.primary.contrastText};

        /* search icon - desktop nav */
        ${(p) => p.theme.media("lg")} {
            fill: ${(p) =>
                p.isSearchActive
                    ? p.theme.color.secondaryOne.contrastText
                    : p.theme.color.primary.contrastText};
        }
    }
`;

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;

    svg {
        margin-top: 4px;
        height: 1.5em;
        width: 1.5em;
    }
`;
