/* tslint:disable:object-literal-sort-keys */

import { css } from "styled-components";
import { theme } from "./theme";

export interface ButtonStylesProps {
    variation?: keyof typeof variations;
    themeSelection?: string;
    invert?: boolean;
}

const variations = {
    redBackgroundLightText: {
        background: theme.color.accentOne.main,
        color: theme.color.accentOne.contrastText,
        borderColor: "transparent",
        hoverBackground: "transparent",
        hoverColor: theme.color.accentOne.main,
        hoverBorderColor: theme.color.accentOne.main,
    },
    purpleBackgroundLightText: {
        background: theme.color.secondaryTwo.main,
        color: theme.color.secondaryOne.contrastText,
        borderColor: "transparent",
        hoverBackground: "transparent",
        hoverColor: theme.color.secondaryTwo.main,
        hoverBorderColor: theme.color.secondaryTwo.main,
    },
    darkPurpleBackgroundLightText: {
        background: theme.color.primary.main,
        color: theme.color.primary.contrastText,
        borderColor: "transparent",
        hoverBackground: "transparent",
        hoverColor: theme.color.primary.main,
        hoverBorderColor: theme.color.primary.main,
    },
    redBackgroundDarkText: {
        background: theme.color.accentOne.main,
        color: theme.color.accentOne.contrastText,
        borderColor: "transparent",
        hoverBackground: "transparent",
        hoverColor: theme.color.accentOne.main,
        hoverBorderColor: theme.color.accentOne.main,
    },
    greenBackgroundDarkText: {
        background: theme.color.accentTwo.main,
        color: theme.color.primary.main,
        borderColor: "transparent",
        hoverBackground: "transparent",
        hoverColor: theme.color.primary.main,
        hoverBorderColor: theme.color.primary.main,
    },
    purpleBackgroundDarkText: {
        background: theme.color.secondaryTwo.main,
        color: theme.color.primary.main,
        borderColor: "transparent",
        hoverBackground: "transparent",
        hoverColor: theme.color.primary.main,
        hoverBorderColor: theme.color.primary.main,
    },
    cyanBackgroundDarkText: {
        background: theme.color.accentTwo.main,
        color: theme.color.primary.main,
        borderColor: "transparent",
        hoverBackground: "transparent",
        hoverColor: theme.color.primary.main,
        hoverBorderColor: theme.color.primary.main,
    },

    // NEW VARIANTS FROM REDESIGN: Still not available in Sanity.

    primary: {
        background: theme.color.primary.main,
        color: theme.color.primary.contrastText,
        borderColor: "transparent",
        hoverColor: theme.color.accentTwo.contrastText,
        hoverBackground: theme.color.accentTwo.main,
        hoverBorderColor: "transparent",
    },
    secondaryOne: {
        background: theme.color.secondaryOne.main,
        color: theme.color.secondaryOne.contrastText,
        borderColor: "transparent",
        hoverColor: theme.color.accentOne.contrastText,
        hoverBackground: theme.color.accentOne.main,
        hoverBorderColor: "transparent",
    },
    secondaryTwo: {
        background: theme.color.secondaryTwo.main,
        color: theme.color.secondaryTwo.contrastText,
        borderColor: "transparent",
        hoverColor: theme.color.accentOne.contrastText,
        hoverBackground: theme.color.accentOne.main,
        hoverBorderColor: "transparent",
    },
    primaryOutline: {
        background: "transparent",
        color: theme.color.primary.main,
        borderColor: theme.color.primary.main,
        hoverColor: theme.color.primary.contrastText,
        hoverBackground: theme.color.primary.main,
        hoverBorderColor: theme.color.primary.main,
    },
    secondaryOneOutline: {
        background: "transparent",
        color: theme.color.secondaryOne.main,
        borderColor: theme.color.secondaryOne.main,
        hoverColor: theme.color.secondaryOne.contrastText,
        hoverBackground: theme.color.secondaryOne.main,
        hoverBorderColor: theme.color.secondaryOne.main,
    },
    lightOutline: {
        background: "transparent",
        color: theme.color.accentOne.contrastText,
        borderColor: theme.color.accentOne.contrastText,
        hoverColor: theme.color.accentOne.contrastText,
        hoverBackground: theme.color.accentOne.main,
        hoverBorderColor: theme.color.accentOne.main,
    },
    text: {
        background: "transparent",
        color: theme.color.secondaryOne.main,
        borderColor: "transparent",
        hoverColor: theme.color.secondaryOne.main,
        hoverBackground: "transparent",
        hoverBorderColor: "transparent",
    },
};

export const buttonStyles = css<ButtonStylesProps>`
    ${(p) => {
        const buttonTheme = (() => {
            // Handle the cases where a variation has been supplied first
            if (p.variation) {
                const currentVariation = variations[p.variation];

                const currentVariationColors = {
                    default: {
                        backgroundColor: currentVariation.background,
                        fontColor: currentVariation.color,
                        borderColor: currentVariation.borderColor,
                    },
                    hover: {
                        backgroundColor: currentVariation.hoverBackground,
                        fontColor: currentVariation.hoverColor,
                        borderColor: currentVariation.hoverBorderColor,
                    },
                };
                if (!p.invert) {
                    return currentVariationColors;
                } else {
                    return {
                        default: currentVariationColors.hover,
                        hover: currentVariationColors.default,
                    };
                }
            }

            // Return the button selected, if it's specified in the page theme.
            const defaultButtonTheme = p.theme.sectionTheme.defaultButton;
            const currentButtonTheme =
                p.theme.sectionTheme[p.themeSelection] || defaultButtonTheme;
            if (currentButtonTheme) {
                if (!p.invert) return currentButtonTheme;

                return {
                    default: currentButtonTheme.hover,
                    hover: currentButtonTheme.default,
                };
            }
        })();

        return css`
            text-transform: uppercase;
            user-select: none;
            white-space: nowrap;
            background: ${buttonTheme.default.backgroundColor};
            color: ${buttonTheme.default.fontColor};
            border: 3px solid ${buttonTheme.default.borderColor};
            border-radius: 100vmax;

            padding: ${p.size
                ? p.theme.buttonSize[p.size]
                : p.theme.buttonSize.s};
            font-weight: ${theme.fontWeight.heavy};
            font-size: ${theme.fontSize.button};
            font-style: ${theme.fontStyle.normal};
            text-align: center;

            > * {
                vertical-align: middle;
            }

            transition: all 140ms;

            :hover {
                color: ${buttonTheme.hover.fontColor};
                background-color: ${buttonTheme.hover.backgroundColor};
                border: 3px solid ${buttonTheme.hover.borderColor};
            }
        `;
    }}
`;
