import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHits } from "react-instantsearch-hooks-web";
import { onRouteChange } from "../../util/events";
import { SearchHitsList } from "./SearchHit";
import { Paragraph } from "../Typography/Paragraph";
import { DefaultBlogHits } from "./DefaultBlogHits";
import { DefaultProductHits } from "./DefaultProductHits";
import { useSearchUIMode } from "./SearchUIModeProvider";
import { AlgoliaSearchBox } from "./AlgoliaSearchBox";
import { SearchResultCategory } from "./const";
import { DesktopFilterSection, MobileFilterSection } from "./Filters";

export const SearchResultsDropDown = () => {
    // the <InstantSearch> gives us hits that we can access using a useInstantSearch hook
    // then we can useHits to get the search results
    // https://www.algolia.com/doc/api-reference/widgets/hits/react-hooks/#hook-usage

    const { results } = useHits();

    const [_, setIsSearchActive] = useSearchUIMode();
    const [hasSearched, setHasSearched] = useState(false);

    useEffect(() => {
        // when the route changes, close search.
        const destroyer = onRouteChange(() => {
            setIsSearchActive(false);
        });
        return destroyer;
    }, []);

    const handleSearchSubmit = () => {
        setHasSearched(true);
    };

    // only show if we have hits.
    if (results.nbHits > 0) {
        return (
            <ResultsDropdownWrapper className="resultsFound">
                <SearchDropDown onSearchSubmit={handleSearchSubmit}>
                    <MobileFilterSection />
                    <SearchHitsList
                        hits={results.hits}
                        category={SearchResultCategory.BlogPost}
                    />
                    <SearchHitsList
                        hits={results.hits}
                        category={SearchResultCategory.Product}
                    />
                    <SearchHitsList
                        hits={results.hits}
                        category={SearchResultCategory.LandingPage}
                    />
                </SearchDropDown>
                <DesktopFilterSection />
            </ResultsDropdownWrapper>
        );
    }
    return (
        <ResultsDropdownWrapper className="noResultsFound">
            <SearchDropDown onSearchSubmit={handleSearchSubmit}>
                {hasSearched && <Paragraph>No results found.</Paragraph>}
                <DefaultBlogHits />
                <DefaultProductHits />
            </SearchDropDown>
        </ResultsDropdownWrapper>
    );
};

/**
 * This is a dropdown close handler which is only rendered when
 * we have a results dropdown showing.
 *
 * The dropdown should close
 * - if the query changes (because the current results arent valid)
 * - if someone clicks outside the dropdown
 *
 */

const SearchDropDown = ({ children, onSearchSubmit }) => {
    return (
        <ResultsDropdown className="searchResultsDropDown">
            <AlgoliaSearchBox onSearchSubmit={onSearchSubmit} />
            <ScrollContainer>{children}</ScrollContainer>
        </ResultsDropdown>
    );
};

const ScrollContainer = styled.div`
    overflow-y: scroll;
    flex-grow: 1;
    padding-right: 32px;
`;
const HEIGHT_OF_MOBILE_NAV = "140px";

const ResultsDropdownWrapper = styled.div`
    display: flex;
    flex-direction: row;
    text-transform: none;
    position: absolute;
    left: calc(-400px + ${(p) => p.theme.height.navBar} - 32px);
    top: ${(p) => p.theme.height.navBar};
`;

const ResultsDropdown = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1em 32px;
    padding-right: 0px;
    border-radius: 0px;
    background: ${(props) => props.theme.color.background.default};
    color: ${(props) => props.theme.color.temp.utilityBlack};
    z-index: 99999;

    /* these change mobile/desktop */
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: -3px;

    width: 100vw;

    padding-top: ${HEIGHT_OF_MOBILE_NAV};
    ${(p) => p.theme.media("lg")} {
        position: unset;
        left: unset;
        top: unset;
        right: unset;
        bottom: unset;
        box-shadow: ${(props) => props.theme.boxShadow.xs};
        padding-top: 1em;
        max-height: 80vh;
        width: 400px;
    }
`;
