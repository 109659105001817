import React from "react";
import styled from "styled-components";
const DefaultSearchIcon = styled.svg`
    .st0 {
        fill: #6633ff;
        stroke: #330066;
        stroke-width: 7.1429;
        stroke-miterlimit: 28.5714;
    }
    .st1 {
        fill: #ffffff;
        stroke: #330066;
        stroke-width: 7.1429;
        stroke-miterlimit: 28.5714;
    }
`;
const DefaultSearchImage = () => (
    <DefaultSearchIcon
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        style={{ enableBackground: "new 0 0 100 100" }}
        xmlSpace="preserve"
    >
        <path
            className="st0"
            d="M68.8,83.5c0.5-7.6,0.7-15.6,0.7-23.7c0-3.3,0-6.5-0.1-9.8c-0.1-2.3-0.8-4.5-2.1-6.3
	c-5.1-6.9-9.2-11.3-15.9-16.4c-1.9-1.4-4.1-2.2-6.5-2.3c-2.3-0.1-4.8-0.1-7.4-0.1c-7.9,0-14.2,0.2-20.8,0.7
	C11.2,26,6.7,30.5,6.3,36.1c-0.5,7.6-0.7,15.6-0.7,23.7c0,8.1,0.3,16,0.7,23.7c0.4,5.6,4.8,10.1,10.5,10.5
	c6.6,0.4,12.9,0.7,20.8,0.7c7.9,0,14.2-0.2,20.8-0.7C64,93.6,68.5,89.1,68.8,83.5z"
        />
        <path
            className="st1"
            d="M93.7,63.9c0.5-7.6,0.7-15.6,0.7-23.7c0-3.3,0-6.5-0.1-9.8c-0.1-2.3-0.8-4.5-2.1-6.3C87,17.2,83,12.9,76.3,7.7
	c-1.9-1.4-4.1-2.2-6.5-2.3c-2.3-0.1-4.8-0.1-7.4-0.1c-7.9,0-14.2,0.2-20.8,0.7C36,6.4,31.5,10.9,31.2,16.5
	c-0.5,7.6-0.7,15.6-0.7,23.7c0,8.1,0.3,16,0.7,23.7C31.5,69.6,36,74,41.7,74.4c6.6,0.5,12.9,0.7,20.8,0.7c7.9,0,14.2-0.2,20.8-0.7
	C88.8,74,93.3,69.6,93.7,63.9z"
        />
    </DefaultSearchIcon>
);

export default DefaultSearchImage;
