import React from "react";
import styled from "styled-components";

const ArrowSvg = () => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 35 38.5"
        >
            <g>
                <g></g>
                <path
                    fill="currentColor"
                    d="M35,6.8v24.9c0,5.2-5.6,8.5-10.1,5.8L3.4,25c-4.5-2.6-4.5-9.1,0-11.7L24.9,0.8C29.4-1.6,35,1.6,35,6.8z"
                />
            </g>
        </svg>
    );
};

interface ArrowProps {
    direction: string;
    color?: string;
    size: string;
    onClick?: () => void;
    hoverColor?: string;
}

const ArrowWrapper = styled.div<ArrowProps>`
    display: flex;
    cursor: pointer;
    svg {
        width: ${(p) => p.size};
        color: ${(p) => p.color};
        ${(p) => {
            switch (p.direction) {
                case "left":
                case "west":
                case "l":
                case "w":
                    return;
                case "right":
                case "east":
                case "r":
                case "e":
                    return "transform: rotate(180deg);";
                case "up":
                case "north":
                case "u":
                case "n":
                    return "transform: rotate(90deg);";
                case "down":
                case "south":
                case "d":
                case "s":
                    return "transform: rotate(-90deg);";
            }
        }}

        transition: color 250ms;
    }

    svg:hover {
        color: ${(p) => (p.hoverColor ? p.hoverColor : p.color)};
    }
`;

export const Arrow: React.FC<ArrowProps> = ({
    direction,
    color,
    size,
    onClick,
    hoverColor,
}: ArrowProps) => {
    return (
        <ArrowWrapper
            direction={direction}
            color={color}
            hoverColor={hoverColor}
            size={size}
            onClick={onClick}
        >
            <ArrowSvg />
        </ArrowWrapper>
    );
};
