import styled from "styled-components";

interface ThemeDivProps {
    marginRight?: string;
    marginLeft?: string;
    marginTop?: string;
    marginBottom?: string;
    paddingRight?: string;
    paddingLeft?: string;
    paddingTop?: string;
    paddingBottom?: string;
    width?: string;
    minWidth?: string;
    maxWidth?: string;
    height?: string;
    minHeight?: string;
    maxHeight?: string;
    margin?: string;
    justifyItems?: string;
    alignItems?: string;
    flexDirection?: string;

    flexWrap?: string;
    gap?: string;
}

/**
 * Handy base wrapper for defining sizes in terms of the theme.
 *
 * <GriddedDiv marginRight="2.5gridSquares">
 * <GriddedDiv marginRight="s">
 * <GriddedDiv width="100%">
 */
const GriddedDiv = styled.div<ThemeDivProps>`
    margin-right: ${(props) => (props.marginRight ? props.marginRight : null)};
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : null)};
    margin-top: ${(props) => (props.marginTop ? props.marginTop : null)};

    margin-bottom: ${(props) =>
        props.marginBottom ? props.marginBottom : null};
    margin: ${(props) => (props.margin ? props.margin : null)};

    padding-right: ${(props) =>
        props.paddingRight ? props.paddingRight : null};
    padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : null)};
    padding-top: ${(props) => (props.paddingTop ? props.paddingTop : null)};
    padding-bottom: ${(props) =>
        props.paddingBottom ? props.paddingBottom : null};

    width: ${(props) => (props.width ? props.width : null)};
    height: ${(props) => (props.height ? props.height : null)};

    max-width: ${(props) => (props.maxWidth ? props.maxWidth : null)};
    max-height: ${(props) => (props.maxHeight ? props.maxHeight : null)};

    min-width: ${(props) => (props.minWidth ? props.minWidth : null)};
    min-height: ${(props) => (props.minHeight ? props.minHeight : null)};
`;

/**
 * Handy wrapper for those times you just need a bog standard row of items
 * by default it centers all items in the row.  Helps to reduce the amount
 * of StyledComponents boilerplate
 *
 * <Flex flexDirection="row" width="100%">
 * </Flex>
 *
 * <FlexRow alignItems="start">
 * </FlexRow>
 */
export const Flex = styled(GriddedDiv)`
    display: flex;
    flex-direction: ${(props) =>
        props.flexDirection ? props.flexDirection : "row"};
    justify-items: ${(props) =>
        props.justifyItems ? props.justifyItems : null};
    align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
    flex-wrap: ${(props) => (props.flexWrap ? props.flexWrap : "unset")};
    gap: ${(props) => (props.gap ? props.gap : "unset")};
    margin: ${(props) => (props.margin ? props.margin : 0)};
`;
