import React, { useContext, createContext } from "react";
import isInternalLink from "../../util/isInternalLink";
import { EA_WWW_WEBSITE_URL } from "../../constants/urls";

interface HyperlinkBehavior {
    shouldOpenInNewTab: (url: string) => boolean;
}
/**
 * easyagile link behavior for pages viewed on easyagile.com
 */
export const EasyAgileHyperlinkBehavior = {
    shouldOpenInNewTab: (url) => {
        return !isInternalLink(url);
    },
};

/**
 * easyagile link behavior for pages viewed on easyagile.partners
 */
export const EasyAgilePartnersHyperlinkBehavior = {
    shouldOpenInNewTab: (url) => {
        try {
            const fullUrl = new URL(url, EA_WWW_WEBSITE_URL); // if it's relative, pretend it's relative to www.easyagile.com
            const isPartnerPortalUrl = /^\/partner-portal/.test(
                fullUrl.pathname
            ); // does the path start with partner portal
            return !isPartnerPortalUrl;
        } catch {
            return true;
        }
    },
};

/**
 * the currently stored link behavior, overridden in partner portal pages
 */
export const HyperlinkBehaviorContext = createContext<HyperlinkBehavior>(
    EasyAgileHyperlinkBehavior
);

/**
 * Get the current link behavior
 * @returns the current behavior of links
 */
export const useHyperlinkBehavior = (): HyperlinkBehavior => {
    const hyperlinkBehaviorContext = useContext(HyperlinkBehaviorContext);
    return hyperlinkBehaviorContext;
};
