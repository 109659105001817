import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import { MdSearch } from "@react-icons/all-files/md/MdSearch";

import { SearchBox } from "react-instantsearch-hooks-web";

const ALGOLIA_SEARCH_INPUT = "algoliaSearchInput";
const ALGOLIA_RESET_BUTTON = "algoliaResetButton";

const SubmitIcon = () => <MdSearch />;

export const AlgoliaSearchBox = ({ onSearchSubmit }) => {
    const searchBoxRef = useRef(null);

    // This is bad and I hate it, but the autoFocus prop from the docs
    // doesn't seem to exist.
    useEffect(() => {
        // If we don't have the ability to query things yet, exit.
        if (!searchBoxRef?.current?.querySelector) return;

        const input = searchBoxRef.current.querySelector(
            `.${ALGOLIA_SEARCH_INPUT}`
        );

        // If we couldn't find the input, exit.
        if (!input) return;

        // Otherwise, set the focus and mark that we have done so.
        input.focus();
    }, [searchBoxRef]);

    return (
        <div ref={searchBoxRef}>
            <StyledSearchBox
                className="search"
                tabIndex={0}
                classNames={{
                    input: ALGOLIA_SEARCH_INPUT,
                    reset: ALGOLIA_RESET_BUTTON,
                }}
                placeholder="Search"
                searchAsYouType={false}
                onSubmit={onSearchSubmit}
                submitIconComponent={SubmitIcon}
            />
        </div>
    );
};

/**
 * Algolia search box with styling.
 * @doc https://www.algolia.com/doc/api-reference/widgets/search-box/react-hooks/
 */
const StyledSearchBox = styled(SearchBox)`
    background: ${(p) => p.theme.color.background.default};
    color: black;
    padding: 0px;
    margin: 0px;
    margin-left: 4px;
    margin-bottom: 1em;
    font-weight: 400;
    padding-right: 0px;

    input {
        border: 0px;
        border-radius: ${(p) => p.theme.borderRadius.s};
        background: ${(p) => p.theme.color.background.dim};
        line-height: 1.125em;
        font-weight: 500;
        font-size: 24px;
        flex-grow: 1;
        min-height: 1.5em;
        padding: 4px 12px;
        min-width: 0px;
    }

    input[type="search"]::-webkit-search-cancel-button {
        -webkit-appearance: none;
    }

    form {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 12px;
    }

    button {
        padding-left: 16px;
        padding-top: 5px;
        padding-right: 16px;

        svg {
            width: 24px;
            height: 24px;
            fill: ${(p) => p.theme.color.primary.main};
            align-self: baseline;
        }
    }

    .${ALGOLIA_RESET_BUTTON} {
        display: none;
    }
`;
