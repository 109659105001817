import React, { ReactNode } from "react";
import { buttonStyles } from "../../styles/button";
import styled from "styled-components";

import { Link } from "gatsby";
import { OutboundLink } from "../../components/Link";
import { LeadCapture } from "../LeadCapture";
import { useHyperlinkBehavior } from "../Link/HyperlinkBehavior";

const ButtonOuter = styled.a`
    ${buttonStyles}
`;

interface ButtonProps {
    href?: string;
    onClick?: () => void;
    children: ReactNode;
    className?: string;
    variation?: string;
    center?: boolean;
    size?: string;
    invert?: boolean;
    themeSelection?: string;
    isGated?: boolean;
    formId?: string;
}

export default function Button({
    children,
    ...props
}: ButtonProps & React.HTMLProps<HTMLButtonElement>) {
    let buttonProps = { ...props } as any;
    const hyperlinkBehavior = useHyperlinkBehavior();
    if (props.href) {
        if (hyperlinkBehavior.shouldOpenInNewTab(props.href) || props.isGated) {
            buttonProps = { ...buttonProps, as: OutboundLink };
        } else {
            buttonProps = { ...buttonProps, as: Link, to: props.href };
            delete buttonProps.href;
        }
    } else {
        // tell google this is a button with no href - so skip crawling it.
        buttonProps = { ...buttonProps, rel: "nofollow", role: "button" };
    }

    return (
        <LeadCapture
            isGated={buttonProps.isGated}
            formId={buttonProps.formId}
            onClick={buttonProps.onClick}
            href={buttonProps.href || buttonProps.to}
        >
            <ButtonOuter {...buttonProps}>{children}</ButtonOuter>
        </LeadCapture>
    );
}
