import React from "react";

import styled from "styled-components";
import { MarketplaceDataContextProvider } from "../components/MarketplaceDataContext";
import { SearchUIModeProvider } from "../components/Search/SearchUIModeProvider";
import { SegmentProvider } from "../util/segment";

export const ChildrenLayout = styled.div`
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

// Gatsby will throw if the provider is rendered as a child of Layout
// Note: this doesn't run in product intros (by design)
export const WithContext = ({ children, pageContext }) => {
    return (
        <SegmentProvider>
            <MarketplaceDataContextProvider
                marketplaceData={pageContext.marketplaceData}
            >
                <SearchUIModeProvider>{children}</SearchUIModeProvider>
            </MarketplaceDataContextProvider>
        </SegmentProvider>
    );
};
