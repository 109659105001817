import React, { useState } from "react";
import { Modal } from "./Modal";
import styled, { ThemeProvider } from "styled-components";
import { useSectionTheme } from "../styles/pageThemes";
import { HubspotForm } from "./HubspotForm";

const ModalWrapper = styled.div`
    background: ${(p) => p.theme.sectionTheme.backgroundColor};
    color: ${(p) => p.theme.sectionTheme.fontColor};
    padding: 40px;
`;

interface Props {
    isGated: boolean;
    formId: string;
    children: JSX.Element | JSX.Element[];
    onClick?: () => void;
    href?: string;
}

export const LeadCapture = ({
    isGated,
    formId,
    children,
    onClick,
    href,
}: Props) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const openModal = (e: React.MouseEvent) => {
        // Allow the click to happen unimpeded if they've already submitted.
        if (hasSubmitted) return;

        // If not, cancel the event and open the modal.
        e.preventDefault();
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    // Handle the form submission.
    const submitHandler = () => {
        // If that all went through, record that we've submitted and execute the provided actions
        setHasSubmitted(true);

        if (onClick instanceof Function) onClick();
        if (href && window) window.open(href, "_blank");

        closeModal();
    };

    if (!isGated) return <>{children}</>;

    return (
        <>
            <ThemeProvider theme={useSectionTheme("deepViolet")}>
                <Modal isOpen={modalOpen} onClose={closeModal} borderRadius="l">
                    <ModalWrapper>
                        <HubspotForm
                            formId={formId}
                            onSuccess={submitHandler}
                        />
                    </ModalWrapper>
                </Modal>
            </ThemeProvider>
            {React.Children.map(children, (child) =>
                // For each child, inject the onClick handler. We do this rather than
                // use a wrapper to avoid unnecessary DOM changes.
                React.cloneElement(child, {
                    onClick: (e: React.MouseEvent) => openModal(e),
                })
            )}
        </>
    );
};
