import { useContext } from "react";
import { ThemeContext } from "styled-components";

export enum ThemeColors {
    deepViolet = "#330066",
    meteorShower = "#6633ff",
    flamingFlamingo = "#e55cff",
    brightTurquoise = "#0aebd2",
    pompelmo = "#ff6666",
    white = "#ffffff",
    black = "#000000",
    grey = "#EDEDED",
    transparent = "transparent",
}

interface ButtonFaceThemeType {
    borderColor: ThemeColors;
    fontColor: ThemeColors;
    backgroundColor: ThemeColors;
}

interface ButtonType {
    default: ButtonFaceThemeType;
    hover: ButtonFaceThemeType;
}

interface SectionThemeType {
    backgroundColor: ThemeColors;
    fontColor: ThemeColors;
    defaultButton: ButtonType;
    accentButton: ButtonType;
    inputBorder: ThemeColors;
}

interface PageThemeType {
    name: string;
    primarySection: SectionThemeType;
    secondarySection: SectionThemeType;
    defaultButton: ButtonType;
    accentButton: ButtonType;
}

const buttonFaces: { [key: string]: ButtonFaceThemeType } = {
    lightOutline: {
        backgroundColor: ThemeColors.transparent,
        fontColor: ThemeColors.white,
        borderColor: ThemeColors.white,
    },
    darkOutline: {
        backgroundColor: ThemeColors.transparent,
        fontColor: ThemeColors.deepViolet,
        borderColor: ThemeColors.deepViolet,
    },
    deepViolet: {
        backgroundColor: ThemeColors.deepViolet,
        fontColor: ThemeColors.white,
        borderColor: ThemeColors.deepViolet,
    },
    meteorShower: {
        backgroundColor: ThemeColors.meteorShower,
        fontColor: ThemeColors.white,
        borderColor: ThemeColors.meteorShower,
    },
    flamingFlamingo: {
        backgroundColor: ThemeColors.flamingFlamingo,
        fontColor: ThemeColors.deepViolet,
        borderColor: ThemeColors.flamingFlamingo,
    },
    brightTurquoise: {
        backgroundColor: ThemeColors.brightTurquoise,
        fontColor: ThemeColors.deepViolet,
        borderColor: ThemeColors.brightTurquoise,
    },
    pompelmo: {
        backgroundColor: ThemeColors.pompelmo,
        fontColor: ThemeColors.deepViolet,
        borderColor: ThemeColors.pompelmo,
    },
};

export const sectionThemes: { [key: string]: SectionThemeType } = {
    default: {
        backgroundColor: ThemeColors.white,
        fontColor: ThemeColors.black,
        defaultButton: {
            default: buttonFaces.deepViolet,
            hover: buttonFaces.darkOutline,
        },
        accentButton: undefined,
        inputBorder: ThemeColors.deepViolet,
    },
    grey: {
        backgroundColor: ThemeColors.grey,
        fontColor: ThemeColors.black,
        defaultButton: {
            default: buttonFaces.deepViolet,
            hover: buttonFaces.darkOutline,
        },
        accentButton: undefined,
        inputBorder: ThemeColors.deepViolet,
    },
    brightTurquoise: {
        backgroundColor: ThemeColors.brightTurquoise,
        fontColor: ThemeColors.deepViolet,
        defaultButton: {
            default: buttonFaces.deepViolet,
            hover: buttonFaces.darkOutline,
        },
        accentButton: undefined,
        inputBorder: ThemeColors.transparent,
    },
    pompelmo: {
        backgroundColor: ThemeColors.pompelmo,
        fontColor: ThemeColors.deepViolet,
        defaultButton: {
            default: buttonFaces.deepViolet,
            hover: buttonFaces.darkOutline,
        },
        accentButton: undefined,
        inputBorder: ThemeColors.transparent,
    },
    flamingFlamingo: {
        backgroundColor: ThemeColors.flamingFlamingo,
        fontColor: ThemeColors.white,
        defaultButton: {
            default: buttonFaces.deepViolet,
            hover: buttonFaces.darkOutline,
        },
        accentButton: undefined,
        inputBorder: ThemeColors.transparent,
    },
    meteorShower: {
        backgroundColor: ThemeColors.meteorShower,
        fontColor: ThemeColors.white,
        defaultButton: {
            default: buttonFaces.deepViolet,
            hover: buttonFaces.lightOutline,
        },
        accentButton: undefined,
        inputBorder: ThemeColors.transparent,
    },
    deepViolet: {
        backgroundColor: ThemeColors.deepViolet,
        fontColor: ThemeColors.white,
        defaultButton: {
            default: buttonFaces.pompelmo,
            hover: buttonFaces.lightOutline,
        },
        accentButton: undefined,
        inputBorder: ThemeColors.transparent,
    },
} as const;

type SectionSelectionType =
    | "primarySection"
    | "secondarySection"
    | (keyof typeof sectionThemes & string);

export const pageThemes: { [key: string]: PageThemeType } = {
    calming: {
        name: "Calming",
        primarySection: sectionThemes.meteorShower,
        secondarySection: sectionThemes.grey,
        defaultButton: {
            default: buttonFaces.deepViolet,
            hover: buttonFaces.darkOutline,
        },
        accentButton: {
            default: buttonFaces.meteorShower,
            hover: buttonFaces.darkOutline,
        },
    },
    whimsical: {
        name: "Whimsical",
        primarySection: sectionThemes.flamingFlamingo,
        secondarySection: sectionThemes.grey,
        defaultButton: {
            default: buttonFaces.deepViolet,
            hover: buttonFaces.darkOutline,
        },
        accentButton: {
            default: buttonFaces.flamingFlamingo,
            hover: buttonFaces.darkOutline,
        },
    },
    rainforest: {
        name: "Rainforest",
        primarySection: sectionThemes.brightTurquoise,
        secondarySection: sectionThemes.grey,
        defaultButton: {
            default: buttonFaces.deepViolet,
            hover: buttonFaces.darkOutline,
        },
        accentButton: {
            default: buttonFaces.brightTurquoise,
            hover: buttonFaces.darkOutline,
        },
    },
    jaffa: {
        name: "Jaffa",
        primarySection: sectionThemes.pompelmo,
        secondarySection: sectionThemes.grey,
        defaultButton: {
            default: buttonFaces.deepViolet,
            hover: buttonFaces.darkOutline,
        },
        accentButton: {
            default: buttonFaces.pompelmo,
            hover: buttonFaces.darkOutline,
        },
    },
    bubblegum: {
        name: "Bubblegum",
        primarySection: sectionThemes.flamingFlamingo,
        secondarySection: sectionThemes.meteorShower,
        defaultButton: {
            default: buttonFaces.deepViolet,
            hover: buttonFaces.darkOutline,
        },
        accentButton: {
            default: buttonFaces.meteorShower,
            hover: buttonFaces.darkOutline,
        },
    },
    neonStory: {
        name: "Neon Story",
        primarySection: sectionThemes.pompelmo,
        secondarySection: sectionThemes.meteorShower,
        defaultButton: {
            default: buttonFaces.deepViolet,
            hover: buttonFaces.darkOutline,
        },
        accentButton: {
            default: buttonFaces.meteorShower,
            hover: buttonFaces.darkOutline,
        },
    },
    lake: {
        name: "Lake",
        primarySection: sectionThemes.brightTurquoise,
        secondarySection: sectionThemes.meteorShower,
        defaultButton: {
            default: buttonFaces.deepViolet,
            hover: buttonFaces.darkOutline,
        },
        accentButton: {
            default: buttonFaces.meteorShower,
            hover: buttonFaces.darkOutline,
        },
    },
} as const;

type PageThemeSelectionType = keyof typeof pageThemes & string;

/**
 * Returns a section theme context for the current page theme.
 * @example
 * ```
 * // Returns the primary section
 * sectionTheme = useSectionTheme("primarySection");
 * ```
 * * You can also pass in a section theme key directly to select it.
 * The returned theme will not alter the page theme.
 * @example
 * ```
 * // Returns the meteor shower section theme
 * sectionTheme = useSectionTheme("meteorShower");
 * ```
 * Use a theme provider to pass this to the section:
 * @example
 * ```
 *     <ThemeProvider theme={sectionTheme}>
 *         {children}
 *     </ThemeProvider>
 * ```
 */
export const useSectionTheme = (level: SectionSelectionType) => {
    const currentTheme = useContext(ThemeContext);

    // We're selecting a section theme directly.
    if (Object.keys(sectionThemes).includes(level)) {
        return {
            ...currentTheme,
            sectionTheme: sectionThemes[level],
        };
    }

    // If no page theme has been selected, default to a grey section theme.
    if (!Object.keys(pageThemes).includes(currentTheme.pageTheme))
        return {
            ...currentTheme,
            sectionTheme: {
                ...sectionThemes.grey,
                accentButton: sectionThemes.grey.defaultButton,
            },
        };

    const chosenTheme = pageThemes[currentTheme.pageTheme];

    // Select a primary section if none (or an invalid option) is provided.
    const chosenSection = Object.keys(chosenTheme).includes(level)
        ? chosenTheme[level]
        : chosenTheme.primarySection;

    // Section themes currently don't have accent buttons, so patch in the same as default.
    chosenSection.accentButton = chosenSection.defaultButton;

    return {
        ...currentTheme,
        sectionTheme: chosenSection,
    };
};

/**
 * Returns a page theme context.
 * @example
 * ```
 * // Returns a page theme context set to bubblegum
 * pageTheme = usePageTheme("bubblegum");
 * ```
 * Use a theme provider to pass this to the page:
 * @example
 * ```
 *     <ThemeProvider theme={pageTheme}>
 *         {children}
 *     </ThemeProvider>
 * ```
 */
export const usePageTheme = (pageTheme: PageThemeSelectionType) => {
    const currentTheme = useContext(ThemeContext);

    // Select the calming theme is none (or an invalid option) is provided.
    const chosenTheme = Object.keys(pageThemes).includes(pageTheme)
        ? pageTheme
        : "calming";

    return {
        ...currentTheme,
        pageTheme: chosenTheme,
        sectionTheme: {
            backgroundColor: ThemeColors.white,
            fontColor: ThemeColors.black,
            defaultButton: pageThemes[chosenTheme].defaultButton,
            accentButton: pageThemes[chosenTheme].accentButton,
        },
    };
};
