import {
    EA_MARKETPLACE_URL,
    MARKETPLACE_URL,
    EA_DEMO_SANDBOX_URL,
} from "../constants/urls";

const marketplaceUrls = [MARKETPLACE_URL, EA_MARKETPLACE_URL];
/**
 * Returns true if URL is one of Easy Agile's product listings on Atlassian Marketplace or our company vendor page.
 *
 * @param {string} url - URL to check.
 */
export const isProductMarketplaceUrl = (url: string): boolean => {
    if (!url) {
        return false;
    }

    return marketplaceUrls.some((marketplaceUrl) =>
        url.startsWith(marketplaceUrl)
    );
};
/**
 * returns true if the URL starts with the demo sandbox
 * @param url
 * @returns true if the URL starts with the demo sandbox
 */
export const isDemoSandboxUrl = (url: string): boolean => {
    if (!url) {
        return false;
    }
    return url.startsWith(EA_DEMO_SANDBOX_URL);
};
/**
 * Generates a URL with a hash on the end. Eg - "https://www.easyagile.com/some-page#some-section"
 * You can optionally pass
 *
 * @param hash - The hash to append to the URL.
 *
 * @param url - An optional URL (string or URL object) to append the hash to.
 * If not passed, the browser location is used (the current page URL).
 *
 * @returns A URL object (not a string) with the hash appended. You can get
 * the URL as a string from the object's `href` property.
 */
export const generateUrlWithHash = (hash: string, url?: string | URL): URL => {
    const resultUrl = new URL(url || window.location.href);
    resultUrl.hash = hash;

    return resultUrl;
};
