import React, { FC } from "react";
import styled, { css } from "styled-components";
import { gridSquares } from "../../styles/grid";

const OuterLayout = styled.section`
    width: 100%;
    padding: ${gridSquares(5)} 0;
    display: flex;
    justify-content: center;
`;

const PageSectionInnerLayout = styled.div<PropsType>`
    width: 100%;
    max-width: ${(p) => (p.noMaxWidth ? `100%` : p.theme.maxWidth.pageSection)};
    padding: 0 ${(p) => p.theme.padding.pageSide};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

interface PropsType {
    noMaxWidth?: boolean;
}

/**
 * @deprecated Use PageSectionContainer for new stuff.
 */
export const PageSection: FC<PropsType> = ({ children, noMaxWidth }) => (
    <OuterLayout>
        <PageSectionInnerLayout noMaxWidth={noMaxWidth}>
            {children}
        </PageSectionInnerLayout>
    </OuterLayout>
);

export const pageSectionContainerPadding = css`
    ${(p) => {
        return `
            @media (min-width: ${p.theme.breakpoint.md}px) {
                padding-right: 48px;
                padding-left: 48px;
            }

            @media (min-width: ${p.theme.breakpoint.lg}px) {
                padding-right: 96px;
                padding-left: 96px;
            }

            @media (min-width: ${p.theme.breakpoint.xl}px) {
                padding-right: 128px;
                padding-left: 128px;
            }
        `;
    }}
`;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    padding: 0 34px;

    ${pageSectionContainerPadding}
`;

const Inner = styled.div`
    max-width: ${(p) => p.theme.maxWidth.pageSectionContainer}px;
    width: 100%;
`;

interface PageSectionContainerProps {
    children: React.ReactNode;
    className?: string;
    id?: string;
}

export const PageSectionContainer = ({
    children,
    className,
    id,
}: PageSectionContainerProps) => {
    return (
        <Container className={className} id={id}>
            <Inner>{children}</Inner>
        </Container>
    );
};
