import { EA_WEBSITE_URL, EA_WWW_WEBSITE_URL } from "../constants/urls";

const isInternalLink = (address: string) => {
    if (!address) return false;

    // for relative links, give a base URI.  This does nothing if address is already a fully
    // qualified URL.
    // https://developer.mozilla.org/en-US/docs/Web/API/URL/URL

    // passing in relative URLS will resolve to an easyagile.com address
    // This: new URL("/foo", "https://easyagile.com").toString()
    // Becomes: https://easyagile.com/foo

    // passing in absolute URLS will resolve to the original absolute address
    // This: new URL("https://marketplace.atlassian.com", "https://easyagile.com").toString()
    // Becomes: https://marketplace.atlassian.com

    try {
        const fullyQualifiedLink = new URL(address, EA_WEBSITE_URL);

        if (
            fullyQualifiedLink.origin === EA_WEBSITE_URL ||
            fullyQualifiedLink.origin === EA_WWW_WEBSITE_URL // some folks have used www.easyagile.com in their links.
        ) {
            return true;
        }
    } catch {
        console.error("Bad link on page", address);
        return false;
    }
    return false;
};

export default isInternalLink;
