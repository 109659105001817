import React, { useEffect } from "react";
import styled from "styled-components";
import { useSearchUIMode } from "./SearchUIModeProvider";

const Z_INDEX_NAV = 10;
const Overlay = styled.div`
    height: 100vh;
    width: 100vw;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    position: fixed;
    z-index: ${Z_INDEX_NAV - 1};

    background-color: ${(p) => p.theme.color.primary.main};
    opacity: 40%;
`;

export const SearchDimmerOverlay = () => {
    const [isSearchActive, setIsSearchActive] = useSearchUIMode();

    useEffect(() => {
        // when search goes active, prevent the body from scrolling.
        if (typeof document !== null) {
            if (isSearchActive) {
                document.body.classList.add("searchDimmerPreventScroll");
            } else {
                document.body.classList.remove("searchDimmerPreventScroll");
            }
        }
        return () => {
            document.body.classList.remove("searchDimmerPreventScroll");
        };
    }, [isSearchActive]);

    if (isSearchActive) {
        return (
            <Overlay
                onClick={() => {
                    setIsSearchActive(false);
                }}
            ></Overlay>
        );
    }
    return null;
};
