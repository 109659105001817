import { Link } from "gatsby";
import React from "react";
import { OutboundLink } from ".";
import styled from "styled-components";
import { LeadCapture } from "../LeadCapture";
import { useHyperlinkBehavior } from "./HyperlinkBehavior";

interface HyperlinkProps {
    className?: string;
    href?: string;
    children?: JSX.Element | JSX.Element[] | string;
    onClick?: () => void;
    isGated?: boolean;
    formId?: string;
}
/** Returns an internal or outbound link depending on href input */
export const Hyperlink = ({
    className,
    href,
    children,
    onClick,
    isGated,
    formId: formId,
    ...props
}: HyperlinkProps) => {
    const url = href;
    const hyperlinkBehavior = useHyperlinkBehavior();
    if (!url) {
        return (
            <LeadCapture
                isGated={isGated}
                formId={formId}
                href={url}
                onClick={onClick}
            >
                <OutboundLink
                    href={url}
                    onClick={onClick}
                    className={className}
                    {...props}
                >
                    {children}
                </OutboundLink>
            </LeadCapture>
        );
    }

    if (!hyperlinkBehavior.shouldOpenInNewTab(url) && !isGated) {
        return (
            <LeadCapture
                isGated={isGated}
                formId={formId}
                href={url}
                onClick={onClick}
            >
                <Link
                    to={url}
                    onClick={onClick}
                    className={className}
                    {...props}
                >
                    {children}
                </Link>
            </LeadCapture>
        );
    }

    // final check after processing
    return (
        <LeadCapture
            isGated={isGated}
            formId={formId}
            href={url}
            onClick={onClick}
        >
            <OutboundLink
                href={url}
                onClick={onClick}
                className={className}
                {...props}
            >
                {children}
            </OutboundLink>
        </LeadCapture>
    );
};

export const UnderlinedLink = styled(Hyperlink)`
    text-decoration: underline;
`;
