import React, { useMemo, useLayoutEffect, useRef } from "react";
// algolia instantsearch hooks
import {
    InstantSearch,
    useInstantSearch,
    Configure,
} from "react-instantsearch-hooks-web";

// algolia search client
import algoliasearch from "algoliasearch/lite";

// algolia search middleware for analytics/insights
import aa from "search-insights";
import { createInsightsMiddleware } from "instantsearch.js/es/middlewares";

import { useSegment } from "../../util/segment";

/**
 * This dynamically loads the <InstantSearch> component
 * It is needed for any of the other algolia parts to work
 *
 * We discovered that loading it causes a hit to fire so we've
 * done two levels of protection:
 *
 * 1. dont load algolia search until they click the button
 * 2. dont fire a query if it is blank.
 *
 * @param param0
 * @returns
 */
export const AlgoliaInstantSearch = ({ isSearchActive, children }) => {
    // just an extra level of paranoia - dont load search until we use it.
    return isSearchActive ? (
        <AlgoliaProvider>{children}</AlgoliaProvider>
    ) : (
        <>{children}</>
    );
};

/**
 * Wrap InstantSearch with a memoised search client
 * https://www.algolia.com/doc/api-reference/widgets/instantsearch/react-hooks/
 * @param param0
 * @returns
 */
const AlgoliaProvider = ({ children }) => {
    const algoliaClient = useMemo(
        () =>
            algoliasearch(
                process.env.GATSBY_ALGOLIA_APP_ID,
                process.env.GATSBY_ALGOLIA_PUBLIC_READONLY_SEARCH_KEY
            ),

        []
    );

    // create the searchClient - this handles the callbacks for algolia
    const searchClient = {
        ...algoliaClient,
        search(requests) {
            // override the default search client to prevent
            // empty searches
            // https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react-hooks/#detecting-empty-search-requests

            if (requests.every(({ params }) => !params.query)) {
                return Promise.resolve({
                    results: requests.map(() => ({
                        hits: [],
                        nbHits: 0,
                        nbPages: 0,
                        page: 0,
                        processingTimeMS: 0,
                    })),
                });
            }

            return algoliaClient.search(requests);
        },
    };

    /*
        Some docs:
        <InstantSearch> https://www.algolia.com/doc/guides/building-search-ui/getting-started/react-hooks/#add-instantsearch-to-your-app
            <Configure> https://www.algolia.com/doc/guides/building-search-ui/getting-started/react-hooks/#configure-search-parameters
            <AlgoliaAnalyticsMiddleware> https://www.algolia.com/doc/guides/building-search-ui/going-further/send-insights-events/react-hooks/#creating-the-insights-middleware
                <SearchBox> https://www.algolia.com/doc/guides/building-search-ui/getting-started/react-hooks/#add-a-search-box
                <Hits> https://www.algolia.com/doc/guides/building-search-ui/going-further/send-insights-events/react-hooks/#sending-events-from-results-widgets

    */
    return (
        <InstantSearch
            searchClient={searchClient}
            indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
        >
            <Configure clickAnalytics={true} enablePersonalization={true} />
            <AlgoliaAnalyticsMiddleware />

            {children}
        </InstantSearch>
    );
};

/**
 * connects to algolia analytics
 * @docs https://www.algolia.com/doc/guides/building-search-ui/going-further/send-insights-events/react-hooks/
 *
 * @returns
 */
const AlgoliaAnalyticsMiddleware = () => {
    const { use } = useInstantSearch();
    const fallbackUID = useMemo(
        () => "ALG-TEST-" + Math.floor(Math.random() * 25000000),
        []
    );
    const segment = useSegment();
    const lastViewedQueryId = useRef("");

    useLayoutEffect(() => {
        const middleware = createInsightsMiddleware({
            insightsClient: aa,
            onEvent: (event) => {
                // If we've just cleared the search, hits may not be defined.
                if (!event?.hits) {
                    return;
                }

                if (event.hits.length <= 0) {
                    return;
                }

                const { insightsMethod, payload, eventType, hits } = event;

                // capture the views top remember the last query id
                if (insightsMethod === "viewedObjectIDs") {
                    // sometimes by the click event it forgets the query id.
                    // save the last viewed query id
                    const currentQueryId = hits[0].__queryID;
                    if (
                        currentQueryId &&
                        lastViewedQueryId.current !== currentQueryId
                    ) {
                        lastViewedQueryId.current = currentQueryId;
                    }

                    // algolia doesn't want you to send it views.  it says it ignores them in the debugger
                    return;
                }

                // send clicks back to algolia.
                if (eventType === "click") {
                    // algolia only wants to see
                    // click needs us to inject position and queryID
                    const insightEvent = {
                        ...payload,
                        eventType,
                        queryID: hits[0].__queryID || lastViewedQueryId.current,
                        positions: [hits[0].position], // < it appears this is used for personalization
                    };

                    // Send the event to Algolia
                    aa(insightsMethod, insightEvent);
                }
            },
        });

        // set the user token.  it can be whatever we want, but we'll choose the segment ID.
        // if that's not available we'll create a random ID
        // This helps with personalization
        // https://www.algolia.com/doc/guides/personalization/going-to-production/in-depth/implementation-checklist/#youre-including-enablepersonalization-and-usertoken-in-your-search-requests
        if (segment) {
            const anonId = segment.user().anonymousId();

            aa("setUserToken", anonId.toString());
        } else {
            aa("setUserToken", fallbackUID);
        }

        return use(middleware);
    }, [use]);

    return null;
};
