import {
    GRID_COLUMN_WIDTH,
    GRID_COLUMN_GAP,
    GRID_COLUMN_AMOUNT,
} from "../constants/theming";

/**
 * Get the combined width (in pixels) that _`columns`_ amount of columns will
 * take up, including the gap between columns.
 *
 * **Hint:** Columns are 128px, gaps between are 20px, and there are 12 columns
 * on the page.
 *
 * @param columns - Amount of columns to span across. Defaults to the total
 * columns amount (12) if left blank.
 *
 * @example
 * ```tsx
 * const containerWidth = calcWidth(6); // Half page width
 * const CoolDiv = styled.div`
 *     max-width: ${containerWidth};
 * `;
 * ```
 */
export const calcWidth = (columns: number = GRID_COLUMN_AMOUNT) => {
    return (GRID_COLUMN_WIDTH + GRID_COLUMN_GAP) * columns - GRID_COLUMN_GAP;
};
