import React, { FC, useEffect, useState } from "react";
import { Location } from "@reach/router";
import { ThemeProvider } from "styled-components";
import { ChildrenLayout, WithContext } from "./components";
import { Footer } from "../components/Footer";
import { Nav } from "../components/Nav";
import { GlobalStyles } from "../styles/GlobalStyles";
import { MEDIA_QUERY_TABLET_SMALL, theme } from "../styles/theme";
import { PromoTeaser } from "../components/PromoTeaserStrip";
import { LayoutSettings } from "../types";
import { SearchDimmerOverlay } from "../components/Search/SearchDimmerOverlay";
import { SessionUTMStorage } from "../components/SessionUTMStorage";
import { DevBanner } from "../components/DevBanner";
import {
    EasyAgileHyperlinkBehavior,
    HyperlinkBehaviorContext,
} from "../components/Link/HyperlinkBehavior";

function handleFirstTab(e) {
    if (e.keyCode === 9) {
        // the "I am a keyboard user" key
        document.body.classList.add("user-is-tabbing");
        window.removeEventListener("keydown", handleFirstTab);
    }
}

const defaultLayoutSettings: LayoutSettings = {
    hasNav: true,
    useLayout: true,
    hasFocusedFooter: false,
    hasFooter: true,
};

// All pages in the app are wrapped with `<Layout>` automatically by
// gatsby-plugin-layout - https://www.gatsbyjs.com/plugins/gatsby-plugin-layout/
// That's why you won't find references to it in the code - it's done at build time.
// State persists between page changes. Useful for things like global nav.
// More info: https://www.gatsbyjs.com/docs/how-to/routing/layout-components/#how-to-prevent-layout-components-from-unmounting
const Layout: FC = ({ children, pageContext }) => {
    const [isNavOpen, setNavOpen] = useState(false);

    const layoutSettings: LayoutSettings =
        pageContext.layoutSettings || defaultLayoutSettings;

    // Will run in product intros as well
    useEffect(() => {
        const handleResize = () => {
            if (isNavOpen && window.innerWidth >= MEDIA_QUERY_TABLET_SMALL) {
                setNavOpen(false);
            }
        };

        window.addEventListener("resize", handleResize);
        window.addEventListener("keydown", handleFirstTab);

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("keydown", handleFirstTab);
        };
    }, []);

    return (
        <Location>
            {(
                {
                    /* location */
                }
            ) =>
                !layoutSettings.useLayout ? (
                    children
                ) : (
                    // Nothing from here down renders in product intros
                    <ThemeProvider theme={theme}>
                        <>
                            <GlobalStyles />
                            <WithContext pageContext={pageContext}>
                                <HyperlinkBehaviorContext.Provider
                                    value={EasyAgileHyperlinkBehavior}
                                >
                                    <>
                                        {layoutSettings.hasNav && (
                                            <>
                                                <DevBanner />
                                                <PromoTeaser />
                                                <Nav />
                                                <SessionUTMStorage />
                                            </>
                                        )}
                                        <ChildrenLayout>
                                            {children}
                                        </ChildrenLayout>
                                        {layoutSettings.hasFooter && (
                                            <Footer
                                                isFocusedFooter={
                                                    layoutSettings.hasFocusedFooter
                                                }
                                            />
                                        )}
                                        <SearchDimmerOverlay />
                                    </>
                                </HyperlinkBehaviorContext.Provider>
                            </WithContext>
                        </>
                    </ThemeProvider>
                )
            }
        </Location>
    );
};

export default Layout;
