export const EA_WEBSITE_URL = "https://easyagile.com"; // this one 301 redirects to www.easyagile.com.
export const EA_WWW_WEBSITE_URL = "https://www.easyagile.com"; // this is the real address of the site

// Docs, support, legal, etc.
export const DOCS_URL = "https://help.easyagile.com";
export const SUPPORT_URL = `${DOCS_URL}/easy-agile-support/`;
export const MIGRATION_HUB_URL = `/migration-hub/`;
export const PARTNER_RESOURCE_CENTER_URL = `/partners/partner-resource-center/`;
export const CUSTOMER_CASE_STUDIES_URL = `/customers/`;
export const LEARNING_HUB_URL = `/learn/`;
export const LICENSE_AGREEMENT_URL = `/trust-center/license-agreement/`;
export const GLOSSARY_URL = "/agile-terminology/";

// Support and Jira service desk
export const ASK_QUESTION = `https://arijea.atlassian.net/servicedesk/customer/portal/2/create/119`;

// Atlassian Marketplace product listings
export const MARKETPLACE_URL = "https://marketplace.atlassian.com/apps";
export const EA_MARKETPLACE_URL =
    "https://marketplace.atlassian.com/vendors/1212045/easy-agile";
export const EA_PROGRAMS_MARKETPLACE_URL = `${MARKETPLACE_URL}/1219491/easy-agile-programs-for-jira`;
export const EA_ROADMAPS_MARKETPLACE_URL = `${MARKETPLACE_URL}/1215695/easy-agile-roadmaps-for-jira`;
export const EA_TEAMRHYTHM_MARKETPLACE_URL = `${MARKETPLACE_URL}/1212078`;
export const EA_PERSONAS_MARKETPLACE_URL = `${MARKETPLACE_URL}/1221392/easy-agile-personas-for-jira?hosting=datacenter&tab=overview`;

// Pricing
export const GetPricingUrl = (atlassianProductId: string): string => {
    return `${MARKETPLACE_URL}/${atlassianProductId}?tab=pricing&hosting=cloud`;
};
// Trial URL
export const GetTrialUrl = (atlassianProductId: string): string => {
    return `${MARKETPLACE_URL}/${atlassianProductId}?tab=overview&hosting=cloud`;
};

// Demos
export const EA_DEMO_SANDBOX_URL = "https://demo.easyagile.com";
export const EA_DEMO_TEAMRHYTHM_URL =
    "https://demo.easyagile.com/demo/teamrhythm";

// Webinars
export const EA_WEBINAR_URL = "https://www.easyagile.com/events/#on-demand";

// Howto
export const EA_TEAMRHYTHM_HOWTO_URL =
    "https://help.easyagile.com/easy-agile-team-rhythm";

// Social
export const EA_TWITTER_URL = "https://twitter.com/easyagile";
export const EA_LINKED_IN_URL = "https://www.linkedin.com/company/easyagile";
export const LINKED_IN_URL_PREFIX = "https://linkedIn.com/in/";
export const CONTACT_EMAIL = "hello@easyagile.com";
export const EA_YOUTUBE_URL = "https://www.youtube.com/c/EasyAgile";
export const EA_FACEBOOK_URL = "https://www.facebook.com/easyagileproducts";
export const EA_INSTAGRAM_URL = "https://www.instagram.com/easyagile";

// Podcasts
export const EA_PODCAST_GOOGLE =
    "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5zaW1wbGVjYXN0LmNvbS9hQlkzcl9iMQ";
export const EA_PODCAST_APPLE =
    "https://podcasts.apple.com/au/podcast/easy-agile/id1529532907";
export const EA_PODCAST_SPOTIFY =
    "https://open.spotify.com/show/5CQYRSaHlSc2TwxrqfAHuQ";

export const EA_PARTNER_RESOURCES_URL = `https://partners.atlassian.com/display/~support19/Easy+Agile+-+Homepage`;
export const JOIN_PARTNER_PROGRAM_URL =
    "https://arijea.atlassian.net/servicedesk/customer/portal/6/group/7/create/33";

// Product intro
export const EA_PRODUCT_INTRO_STAGING_URL = `https://product-intro.easyagile.co`;
export const EA_PRODUCT_INTRO_PRODUCTION_URL = `https://product-intro.easyagile.zone`;

// Products
export const USER_STORY_MAPS_PATH = `/products/user-story-maps/`;
export const TEAMRHYTHM_PATH = `/products/teamrhythm`;
export const ROADMAPS_PATH = `/products/roadmaps/`;
export const PROGRAMS_PATH = `/products/programs/`;
export const PERSONAS_PATH = `/products/personas/`;
export const STORY_MAPS_PUBLIC_ROADMAP_PATH = `/products/user-story-maps/roadmap/`;
export const PROGRAMS_PUBLIC_ROADMAP_PATH = `/products/programs/roadmap/`;

// Careers
export const CAREERS_GENERAL_APPLY = `https://jobs.lever.co/easyagile/fb1852e7-32b5-4f48-9639-df9e481049f6/apply`;

// Other internal pages
export const COMPANY_PATH = "/company/";
export const CAREERS_PATH = "/careers/";
export const PLEDGE_PATH = "/pledge/";
export const REPORTS_PATH = "/reports/";
export const IN_THE_PRESS_PATH = "/in-the-press/";
export const PARTNERS_PATH = "/partners/";
export const PARTNER_PROGRAM_PATH = "/partners/program/";
export const PRODUCTS_PATH = "/products/";
export const BLOG_PATH = "/blog/";
export const PODCAST_PATH = "/podcast/";
export const TRUST_CENTER_URL = "/trust-center/";
export const CONTACT_US_URL = "/contact/";
export const EVENTS_URL = `/events/`;
export const GET_DEMO = "/demo/";

export const PUBLIC_ROADMAP = "https://updates.easyagile.com/board";

export const UNSUBSCRIBE_URL = `https://easyagile.us12.list-manage.com/unsubscribe?u=fed7d2b76baedefc26f8d3d2c&id=6fe2afb4c7`;

export const EPIC_STATUS_WHITEPAPER_URL = `https://blog.easyagile.com/understanding-the-cycle-time-of-epics-in-jira-6fcddea1f8cf`;

export const MAILCHIMP_ENDPOINT = `https://easyagile.us12.list-manage.com/subscribe/post?u=fed7d2b76baedefc26f8d3d2c&amp;id=267a4e7676`;

// This is used in "same-as" listing
export const EA_SOCIALS_LIST = [
    // List of related accounts (social, other searchable websites)
    EA_MARKETPLACE_URL,
    EA_TWITTER_URL,
    EA_LINKED_IN_URL,
    EA_YOUTUBE_URL,
    EA_FACEBOOK_URL,
    EA_INSTAGRAM_URL,
    EA_PODCAST_APPLE,
    EA_PODCAST_GOOGLE,
    EA_PODCAST_SPOTIFY,
];
