import React from "react";
import { ViewportSize } from "../../styles/theme";
import styled, { css } from "styled-components";

export const generateHeadingStyles = (
    levelStyle: HeadingLevel,
    noTopMargin: boolean,
    noBottomMargin: boolean,
    viewportSize?: ViewportSize
) => {
    const size = viewportSize || "xs"; // xs is default, no media query necessary

    return css`
        ${(p) => {
            const vals = p.theme.viewport[size].typography[levelStyle];

            const styleRules = `
                font-size: ${vals.fontSize};
                font-weight: ${vals.fontWeight};
                line-height: ${vals.lineHeight};
                letter-spacing: ${vals.letterSpacing};
                margin-top: ${noTopMargin ? "0px" : vals.marginTop};
                margin-bottom: ${noBottomMargin ? "0px" : vals.marginBottom};

                :first-child {
                    margin-top: 0;
                }

                :last-child {
                    margin-bottom: 0;
                }
            `;

            if (size === "xs") {
                return styleRules;
            }

            return `
                ${p.theme.media(size)} {
                    ${styleRules}
                }
            `;
        }};
    `;
};

const headingStyles = css<{
    levelStyle: HeadingLevel;
    noTopMargin: boolean;
    noBottomMargin: boolean;
}>`
    ${(p) =>
        generateHeadingStyles(p.levelStyle, p.noTopMargin, p.noBottomMargin)};
    ${(p) =>
        generateHeadingStyles(
            p.levelStyle,
            p.noTopMargin,
            p.noBottomMargin,
            "sm"
        )};
    ${(p) =>
        generateHeadingStyles(
            p.levelStyle,
            p.noTopMargin,
            p.noBottomMargin,
            "md"
        )};
    ${(p) =>
        generateHeadingStyles(
            p.levelStyle,
            p.noTopMargin,
            p.noBottomMargin,
            "lg"
        )};
    ${(p) =>
        generateHeadingStyles(
            p.levelStyle,
            p.noTopMargin,
            p.noBottomMargin,
            "xl"
        )};
    ${(p) =>
        generateHeadingStyles(
            p.levelStyle,
            p.noTopMargin,
            p.noBottomMargin,
            "xxl"
        )};
    ${(p) =>
        generateHeadingStyles(
            p.levelStyle,
            p.noTopMargin,
            p.noBottomMargin,
            "xxxl"
        )};
`;

/**
 * @private
 * Makes a <h2> tag by default, but this changes to another heading level
 * based on the `as` prop passed by `Heading`.
 */
const StyledHeading = styled.h2<{
    levelStyle: HeadingLevel;
    noTopMargin: boolean;
    noBottomMargin: boolean;
}>`
    ${headingStyles}
`;

export type HeadingLevel = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

type HeadingProps = React.ComponentProps<typeof StyledHeading> & {
    level: HeadingLevel;
    levelStyle?: HeadingLevel;
    noMargin?: boolean;
};

/**
 * A generic heading component that renders a h1, h2, etc based on the chosen
 * heading level. It's responsive and automatically resizes at various breakpoints.
 *
 * @param level - The heading level/size. This sets both the semantic heading
 *      element rendered in the DOM (h1, h2, etc) and styles.
 * @param levelStyle - (Optional) Overrides the styles for `level`. You can
 *      use this to make a semantic h4 look like a h2, for example.
 * @param as - A styled-components `as` prop. Like `level`, it sets the rendered
 *      DOM element, but it can be any arbitrary element. Eg - "div", "p", "span".
 */
export const Heading: React.FC<HeadingProps> = ({
    level,
    levelStyle = level,
    as,
    noMargin = false,
    noTopMargin = false,
    noBottomMargin = false,
    ...props
}) => {
    return (
        <StyledHeading
            as={as || level}
            levelStyle={levelStyle}
            noTopMargin={noTopMargin || noMargin}
            noBottomMargin={noBottomMargin || noMargin}
            {...props}
        ></StyledHeading>
    );
};
