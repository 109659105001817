import React from "react";
import styled, { css } from "styled-components";

export const inputStyles = css`
    width: 100%;
    padding: 16px;
    height: 64px;

    color: ${(p) => p.theme.color.background.contrastText};
    background: ${(p) => p.theme.color.background.default};
    border: 1px solid ${(p) => p.theme.sectionTheme.inputBorder};

    border-radius: ${(p) => p.theme.borderRadius.s};

    ::placeholder {
        color: ${(p) => p.theme.color.background.subtleText};
    }

    :not(:enabled) {
        background: ${(p) => p.theme.color.background.dim};
        ::placeholder {
            color: ${(p) => p.theme.color.background.contrastText};
        }
    }
`;

const StyledInput = styled.input`
    ${inputStyles}
`;

interface InputProps
    extends Omit<React.HTMLProps<HTMLInputElement>, "ref" | "as"> {
    changeHandler: (v: string) => void;
}

export const Input = ({
    changeHandler,
    required,
    label,
    ...inputProps
}: InputProps) => {
    return (
        <StyledInput
            aria-label={label}
            placeholder={label + (required ? "*" : "")}
            required={required}
            onChange={(e) => changeHandler(e.target.value)}
            {...inputProps}
        />
    );
};
