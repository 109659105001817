import React, { createContext, useContext } from "react";

import {
    MarketplaceProducts,
    MarketplaceProduct,
    MarketplaceProductReview,
} from "../types";

const MarketplaceDataContext = createContext<any | undefined>(undefined);

/**
 * Lets us push through pageContext to child components.
 * Could have been named pageContext but didn't want it to
 * get mixed up with gatsby concepts.
 *
 * Why even do this?
 *
 * Lets us dynamically create a gql query at build time
 * based on product ids and pass through the pageContext
 *
 * @returns the pageContext from the parent page
 */
export const useMarketplaceData = (): MarketplaceProducts | undefined => {
    const marketplaceData = useContext(MarketplaceDataContext);
    return marketplaceData;
};
/**
 * Look through the marketplace data for a specific product
 * @param marketplaceData the result of useMarketplaceData
 * @param appId
 * @returns
 */
export const findMarketplaceProductByAppId = (
    marketplaceData: MarketplaceProducts,
    appId: string
): MarketplaceProduct => {
    if (!appId) return null;
    const marketplaceProducts: MarketplaceProduct[] =
        Object.values(marketplaceData);

    return marketplaceProducts.find((marketplaceApp: MarketplaceProduct) => {
        return marketplaceApp.appId === appId;
    }) as MarketplaceProduct;
};

/**
 *
 * @param marketplaceData
 * @param appId
 * @returns
 */
export const getMarketplaceProductReview = (
    marketplaceData: MarketplaceProducts,
    appId: string
): MarketplaceProductReview => {
    const marketplaceProduct = findMarketplaceProductByAppId(
        marketplaceData,
        appId
    );
    if (!marketplaceProduct) return null;
    // grab the data from the atlassian query and re
    return {
        rating: marketplaceProduct.reviewSummary.rating,
        numReviews: marketplaceProduct.reviewSummary.count,
        numInstalls: marketplaceProduct.distribution.installationCount,
    };
};

/**
 * Go through all the marketplace data and sum up the total ratings
 * @param marketplaceData
 * @returns
 */
export const getAggregateMarketplaceReviews = (marketplaceData) => {
    const reviewSummary = {
        rating: 0,
        numReviews: 0,
        numInstalls: 0,
    };
    const marketplaceProducts: MarketplaceProduct[] =
        Object.values(marketplaceData);

    // tally up the reviews, installs
    marketplaceProducts.forEach((app: MarketplaceProduct) => {
        reviewSummary.numInstalls += app.distribution.installationCount;
        reviewSummary.rating +=
            app.reviewSummary.rating * app.reviewSummary.count;
        reviewSummary.numReviews += app.reviewSummary.count;
    });

    // average out the rating across the number of reviews
    if (reviewSummary.numReviews > 0) {
        reviewSummary.rating = reviewSummary.rating / reviewSummary.numReviews;
    }

    return reviewSummary;
};

export const useMarketplaceAppData = (
    appId
): MarketplaceProducts | undefined => {
    const marketplaceData = useContext(MarketplaceDataContext);
    return marketplaceData.find((product) => {
        return product.appId === appId;
    });
};

export const MarketplaceDataContextProvider = ({
    children,
    marketplaceData,
}) => {
    return (
        <>
            <MarketplaceDataContext.Provider value={marketplaceData}>
                {children}
            </MarketplaceDataContext.Provider>
        </>
    );
};
