import React from "react";
import { MobileNav } from "./MobileNav";
import { DesktopNav } from "./DesktopNav";

export const Nav = () => {
    return (
        <>
            <MobileNav />
            <DesktopNav />
        </>
    );
};
