import React from "react";
import styled from "styled-components";

export const SimpleDotLoader = () => (
    <SimpleSpinner>
        <Dot />
        <Dot />
        <Dot />
    </SimpleSpinner>
);

const ANIMATION_TIME = 1500;

const Dot = styled.div`
    border-radius: 1em;
    margin: 0.25em;
    width: 1em;
    aspect-ratio: 1 / 1;

    background-color: ${(p) => p.theme.sectionTheme.fontColor};
    animation: scaleDots ${ANIMATION_TIME}ms infinite ease-in-out;

    /* Safari */
    @keyframes scaleDots {
        0% {
            transform: scale(1, 1);
        }
        50% {
            transform: scale(1.5, 1.5);
        }
        100% {
            transform: scale(1, 1);
        }
    }
`;

const SimpleSpinner = styled.div`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 1em;
    width: 3em;

    /* control the timing of the children*/
    & > :nth-child(1) {
        animation-delay: 0ms;
    }
    & > :nth-child(2) {
        animation-delay: ${(ANIMATION_TIME * 1.5) / 6}ms;
    }
    & > :nth-child(3) {
        animation-delay: ${(ANIMATION_TIME * 3) / 6}ms;
    }
`;
