import { Link } from "gatsby";
import { gridSquares } from "../../styles/grid";
import styled, { css } from "styled-components";
import React from "react";
import { calcWidth } from "../../util/theming";
import { downFadeInAnimation } from "../Animations";
import { Hyperlink } from "../Link/Hyperlink";

export const NavItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${gridSquares(0.5)};
    position: relative;

    ${(p) => p.theme.media("lg")} {
        height: 100%;
        right: 0;
        vertical-align: middle;
        padding: 0px;

        :hover {
            background-color: ${(p) => p.theme.color.secondaryOne.main};
        }
    }
`;

export const SubNavWrapper = styled.div<{ $isOpen?: boolean }>`
    ${(p) => {
        if (p.$isOpen)
            return css`
                display: flex;
                flex-direction: column;
                animation: ${downFadeInAnimation} 0.35s ease-in;
            `;

        return "display: none;";
    }}

    ${(p) => p.theme.media("lg")} {
        position: absolute;
        z-index: 10;
        top: 100%;
    }
`;

type NavBarDisplayType = "mobile" | "desktop";

export const NavBar = styled.nav<{
    $display: NavBarDisplayType;
}>`
    display: ${(p) => (p.$display === "mobile" ? "grid" : "none")};
    height: ${(p) => p.theme.height.navBar};
    background: ${(p) => p.theme.color.primary.main};

    width: 100vw;
    position: sticky;
    top: 0px;
    z-index: 10;
    justify-items: center;

    padding-left: ${gridSquares(2)};
    padding-right: ${gridSquares(2)};

    ${(p) => p.theme.media("md")} {
        padding-left: ${gridSquares(3)};
        padding-right: ${gridSquares(3)};
    }

    ${(p) => p.theme.media("lg")} {
        display: ${(p) => (p.$display === "mobile" ? "none" : "grid")};
    }

    ${(p) => p.theme.media("xl")} {
        padding-left: ${gridSquares(8)};
        padding-right: ${gridSquares(8)};
    }
`;

export const TopLayoutContent = styled.div`
    max-width: ${calcWidth(12)}px;
    width: 100%;
    height: ${(p) => p.theme.height.navBar};

    display: flex;
    align-items: center;

    position: relative;
`;

export const CompanyLogo = styled(Link)<{ $color?: string; $isOpen?: boolean }>`
    display: flex;
    align-items: center;
    flex-grow: 1;
    color: ${(p) => p.$color};
    z-index: 20;

    svg {
        width: 154px;
        max-width: 154px;
        height: 39px;
    }

    ${(p) =>
        p.$isOpen &&
        css`
            visibility: hidden;
        `}
`;

export const NavHeading = styled.div<{ $clickable?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: ${(p) => p.theme.fontWeight.heavy};
    text-transform: uppercase;
    color: ${(p) => p.theme.color.secondaryOne.contrastText};
    gap: 8px;
    ${(p) => p.$clickable && "cursor: pointer;"}

    ${(p) => p.theme.media("lg")} {
        pointer-events: none;
        height: ${(p) => p.theme.height.navBar};
        padding: 0 ${gridSquares(2)};

        position: relative;

        :not(:last-child) {
            cursor: default;
        }
    }
`;

export const NavHeadingText = styled.span`
    font-size: ${(p) => p.theme.fontSize.heading4};
    font-weight: ${(p) => p.theme.fontWeight.heavy};
    text-transform: uppercase;
    color: ${(p) => p.theme.color.secondaryOne.contrastText};
    pointer-events: none;

    ${(p) => p.theme.media("lg")} {
        font-size: ${(p) => p.theme.fontSize.heading6};
    }
`;

const textLinkStyles = css`
    font-weight: ${(p) => p.theme.fontWeight.medium};
    font-size: ${(p) => p.theme.fontSize.heading5};

    padding: 0px;
    color: ${(p) => p.theme.color.primary.contrastText};

    position: relative;
    display: flex;
    flex-direction: column;

    ${(p) => p.theme.media("lg")} {
        padding: ${gridSquares(1)};
        :hover {
            text-decoration: underline;
        }

        width: 16em;
        height: ${gridSquares(4)};
        background-color: ${(p) => p.theme.color.secondaryOne.main};
        justify-content: center;
        font-size: ${(p) => p.theme.fontSize.bodyText};
    }
`;

const StyledLink = styled(Hyperlink)<{ children?: React.ReactNode }>`
    ${textLinkStyles}
`;

interface LinkProps {
    onClick?: () => void;
    url: string;
    children: JSX.Element;
}

export const TextLink = ({ url, children, onClick }: LinkProps) => {
    return (
        <StyledLink href={url} onClick={onClick}>
            {children}
        </StyledLink>
    );
};
